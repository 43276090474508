import { FC, ReactNode, useRef, useContext, useState } from 'react';
import {
	FormControl,
	FormControlProps,
	FormErrorMessage,
	FormLabel,
	FormLabelProps,
	Input,
	InputGroup,
	InputProps,
	Tooltip,
	Text,
	Flex,
	InputRightElement,
	Skeleton,
	VStack,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import CopyToClipboard from '../../CopyToClipboard';
import AssistantIcon from '../../AssistantIcon';
import { useProductFormModalContext } from 'src/contexts/ProductFormModalContext';
export interface StringInputProps {
	label?: string;
	subLabel?: string;
	requirementsLabel?: string;
	id?: string;
	placeholder?: string;
	type?: 'text' | 'email' | 'password' | 'date';
	error?: ReactNode;
	tooltip?: string;
	required?: boolean;
	isFocusSet?: boolean;
	copyToClipboard?: string;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	inputProps?: InputProps;
	maxLength?: number;
	valueLength?: number;
	withAssistant?: boolean;
	isLoading?: boolean;
	withErrorMessage?: boolean;
	direction?: 'row' | 'column';
	handleOpenAssistantChat?: (ref?: HTMLDivElement) => void;
	requirementsLabelStyle?: React.CSSProperties;
	shouldShowSkeleton?: boolean;
}

const StringInput: FC<StringInputProps> = ({
	error,
	label,
	subLabel,
	requirementsLabel,
	requirementsLabelStyle,
	placeholder,
	type = 'text',
	tooltip,
	required,
	formControlProps,
	formLabelProps,
	inputProps,
	isFocusSet,
	copyToClipboard,
	maxLength,
	valueLength,
	withAssistant,
	isLoading,
	withErrorMessage = true,
	direction = 'column',
	handleOpenAssistantChat,
	shouldShowSkeleton = true,
}) => {
	const inputRef = useRef<HTMLDivElement | null>(null);
	const hasError = !!error;
	const productFormModalContext = useProductFormModalContext();
	const isProductFormModal = productFormModalContext?.isProductFormModal;
	const [isFocused, setIsFocused] = useState(false);

	const renderLabelIndicator = () => {
		if (!required) return null;

		if (error) return <InfoIcon color="#e53e3e" ml="3px" />;

		return (
			<Text color="#e53e3e" ml="2px">
				*
			</Text>
		);
	};

	const onOpenAssistant = () => {
		inputRef.current &&
			handleOpenAssistantChat &&
			handleOpenAssistantChat(inputRef?.current);
	};

	const errorFocusProps =
		error && isFocusSet
			? {
					boxShadow: '0 0 0 1px #E53E3E',
					borderColor: '#E53E3E',
			  }
			: {
					boxShadow: '0 0 0 1px #3182ce',
					borderColor: '#3182ce',
			  };

	return (
		<FormControl w="full" isInvalid={hasError} {...formControlProps}>
			<Flex direction={direction} gap={direction === 'row' ? 20 : 0}>
				{label &&
					(isProductFormModal ? (
						<Flex flex={1} direction="row" justify="space-between">
							<FormLabel
								mb="0"
								display="flex"
								alignItems="center"
								{...formLabelProps}
							>
								{label}
								{renderLabelIndicator()}
								<Text as="span" color="#718096" fontSize="smaller">
									{subLabel}
								</Text>
							</FormLabel>
							{requirementsLabel && (
								<Text textAlign="right" fontSize="12px" color="#959595">
									{requirementsLabel}
								</Text>
							)}
						</Flex>
					) : (
						<Flex flex={1} justify="space-between">
							<FormLabel mb="15px" {...formLabelProps}>
								<Flex gap={1} alignItems="center">
									{label}
									{subLabel && (
										<Text ml={1} color="#718096" fontSize="smaller">
											{subLabel}
										</Text>
									)}
									{renderLabelIndicator()}
								</Flex>
								{requirementsLabel && (
									<Flex w="110%">
										<Text
											fontSize="12px"
											color="#959595"
											style={requirementsLabelStyle}
										>
											{requirementsLabel}
										</Text>
									</Flex>
								)}
							</FormLabel>
							{copyToClipboard && (
								<CopyToClipboard value={copyToClipboard ?? ''} size="xs" />
							)}
						</Flex>
					))}
				<VStack flex={1} alignItems="flex-start" gap={0}>
					{tooltip ? (
						<Tooltip label={tooltip} aria-label="Input tooltip">
							<Input
								type={type}
								placeholder={placeholder}
								maxLength={maxLength}
								mt={isProductFormModal ? 0 : 1}
								{...inputProps}
								_focusVisible={errorFocusProps}
							/>
						</Tooltip>
					) : isLoading && shouldShowSkeleton ? (
						<Skeleton h="40px" w="full" borderRadius="5px" {...inputProps} />
					) : (
						<InputGroup ref={inputRef}>
							{withAssistant && handleOpenAssistantChat && (
								<InputRightElement alignItems="end" h="full" right={2} top={-1}>
									<AssistantIcon onOpen={onOpenAssistant} />
								</InputRightElement>
							)}

							<Input
								bg="white"
								placeholder={placeholder}
								fontSize="14px"
								marginTop={isProductFormModal ? 2 : 0}
								fontWeight="400"
								lineHeight="normal"
								_placeholder={{ color: '#A0AEC0' }}
								borderRadius="5px"
								maxLength={maxLength}
								{...inputProps}
								type={type}
								_focusVisible={errorFocusProps}
								onFocus={() => setIsFocused(true)}
								onBlur={() => setIsFocused(false)}
								data-testid="string-input"
							/>
						</InputGroup>
					)}
					<Flex
						alignItems="center"
						justifyContent={
							error && !maxLength
								? 'flex-start'
								: !error && maxLength
								? 'flex-end'
								: error && maxLength
								? 'space-between'
								: 'flex-end'
						}
					>
						{error && withErrorMessage && (
							<FormErrorMessage mt={0}>{error}</FormErrorMessage>
						)}

						{!!maxLength && (
							<Text color="#A0AEC0" textAlign="right" pr={2} pt={0.5}>
								{valueLength}/{maxLength}
							</Text>
						)}
					</Flex>
				</VStack>
			</Flex>
		</FormControl>
	);
};

export default StringInput;
