import { z } from 'zod';

export interface IIntegrationsForm {
	//TODO: type this
	adAccount?: any;
}

export const IntegrationsSchema: Zod.ZodType<IIntegrationsForm> = z.object({
	//TODO: type this
	adAccount: z.any().optional(),
});

export interface IPage {
	id: string;
	name: string;
	image?: string;
	access_token?: string;
}

export interface IIntegrationItem {
	enabled: boolean;
	attributes: Record<string, any>;
}
export interface IGoogleIntegrationToken {
	accessToken: string;
	refreshToken?: string;
	expiresAt: number;
	tokenType: string;
}

export interface IGoogleIntegrationCustomers {
	resourceName: string;
	id: string;
	descriptiveName: string;
	currencyCode: string;
	timeZone: string;
	autoTaggingEnabled: boolean;
	customerClient: string;
	manager: boolean;
	status: string;
}

export interface IGoogleIntegrationAttributes {
	tokens?: IGoogleIntegrationToken;
	customers?: IGoogleIntegrationCustomers[];
}

export interface IGoogleIntegration {
	enabled: boolean;
	attributes?: IGoogleIntegrationAttributes;
}

export interface IIntegration {
	user: string;
	social: {
		fb: IIntegrationItem;
		twitter: IIntegrationItem;
		google?: IGoogleIntegration;
	};
}
