import { Box, Flex, useOutsideClick } from '@chakra-ui/react';
import { useContext, useEffect, useRef } from 'react';
import { AssistantChatContext } from 'src/contexts';
import Header from './header';
import Messages from './messages';
import Actions from './actions';
import { CHANNELS_NAME_MAPPING } from 'src/lib/constants/campaign';

const HEADER_HEIGHT = '50px';

const AssistantChat = () => {
	const {
		thread,
		field,
		isChatCollapsed,
		setIsChatCollapsed,
		markMessageAsSeen,
	} = useContext(AssistantChatContext);
	const chatBoxRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		!isChatCollapsed && markMessageAsSeen();
	}, [isChatCollapsed, thread]);

	// useOutsideClick({
	// 	ref: chatBoxRef,
	// 	handler: (event) => {
	// 		const modal = document.querySelector('.chakra-modal__content-container');
	// 		if (modal && modal.contains(event.target as Node)) {
	// 			return;
	// 		}
	// 		setIsChatCollapsed(true);
	// 	},
	// });

	const getTitle = field?.channel
		? `Option ${field?.variant} (${
				CHANNELS_NAME_MAPPING[field?.channel]
		  } / ${field?.displayName})`
		: field?.displayName;

	return (
		<Box
			ref={chatBoxRef}
			position="fixed"
			bottom={isChatCollapsed ? 0 : undefined}
			top={isChatCollapsed ? undefined : 0}
			right={0}
			h={isChatCollapsed ? HEADER_HEIGHT : '100vh'}
			w={isChatCollapsed ? '400px' : '100vw'}
			zIndex={2000}
			bg="white"
			overflow="hidden"
			boxShadow="lg"
			borderLeftWidth="1px"
			borderColor="#CDCDCD"
		>
			<Flex
				cursor={isChatCollapsed ? 'pointer' : 'default'}
				direction="column"
				h="full"
			>
				<Header
					height={HEADER_HEIGHT}
					onClick={() => setIsChatCollapsed(!isChatCollapsed)}
					fieldName={getTitle}
					currentContent={field?.content}
				/>
				{!isChatCollapsed && thread && (
					<Messages conversation={thread.conversation} />
				)}
				{!isChatCollapsed && <Actions />}
			</Flex>
		</Box>
	);
};

export default AssistantChat;
