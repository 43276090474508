import { useContext, useEffect, useState } from 'react';
import { Button, Flex, Link, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { StringInputHook } from 'src/components/common/form';
import UserContext from 'src/contexts/UserContext';
import AlertMessage from '../common/AlertMessage';
import authService from 'src/services/auth';
import { SingUpSchema, ISingUpFOrm } from 'src/lib/schemas';

interface CustomWindow extends Window {
	dataLayer: Array<Record<string, any>>;
}
declare const window: CustomWindow;

const SignUpForm = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const formMethods = useForm<ISingUpFOrm>({
		resolver: zodResolver(SingUpSchema),
	});
	const { setUser } = useContext(UserContext);
	const navigate = useNavigate();
	const [search] = useSearchParams();
	const redirectUrl = search.get('redirect_url');

	useEffect(() => {
		formMethods.reset({
			email: '',
			name: '',
			accountName: '',
			password: '',
		});
	}, [formMethods]);

const onSubmit = formMethods.handleSubmit(async (formData) => {
    setError(null);
    setIsLoading(true);
    try {
        const user = await authService.signup(formData);

        if (user && !user.isEmailVerified) {
            window.dataLayer.push({
                event: 'successful_sign_up',
                user_id: user.id,
                email: user.email,
                name: user.name,
                account_id: user.account,
            });
            authService.cleanupSession();
            setUser(null);
            navigate('/pending-verification');
        }	
    } catch (error: any) {
        const { message } = error.response?.data || 'An error occurred';
        setError(message);
    }
    formMethods.reset({ password: '' });
    setIsLoading(false);
});

	return (
		<Flex flex={1} direction="column" gap={3}>
			<FormProvider {...formMethods}>
				{error && <AlertMessage status="error">{error}</AlertMessage>}
				<form onSubmit={onSubmit} autoComplete="off">
					<Flex
						flexDirection="column"
						justifyContent="center"
						height="100%"
						gap="28px"
					>
						<StringInputHook
							label="Email"
							name="email"
							placeholder="you@company.com"
							required
							inputProps={{
								size: 'md',
								fontSize: '14px',
								autoComplete: 'new-password',
							}}
						/>
						<StringInputHook
							label="Name"
							name="name"
							placeholder="Your full name"
							required
							inputProps={{
								size: 'md',
								fontSize: '14px',
								autoComplete: 'new-password',
							}}
						/>
						<StringInputHook
							label="Company name"
							name="accountName"
							placeholder="Your company name"
							required
							inputProps={{
								size: 'md',
								fontSize: '14px',
								autoComplete: 'new-password',
							}}
						/>
						<Flex direction="column" gap="3">
							<StringInputHook
								label="Password"
								name="password"
								type={isPasswordVisible ? 'text' : 'password'}
								placeholder="Strong password"
								required
								inputProps={{
									size: 'md',
									fontSize: '14px',
									autoComplete: 'new-password',
								}}
							/>
							<Flex alignItems="center">
								<input
									type="checkbox"
									name="showPassword"
									id="showPassword"
									onChange={() => setIsPasswordVisible(!isPasswordVisible)}
								/>
								<label htmlFor="showPassword">
									<Text fontSize="13px">&nbsp; Show password</Text>
								</label>
							</Flex>
						</Flex>
						<Flex
							justifyContent="space-between"
							alignItems="center"
							w="full"
							gap={1}
						>
							<Text>
								By clicking “Sign Up” you agree to our{' '}
								<Link target='_blank' href='https://www.fusionos.ai/terms-of-service' textDecor="underline">Terms of Service</Link> and{' '}
								<Link target='_blank' textDecor="underline" href='https://www.fusionos.ai/privacy-policy'>Privacy Notice</Link>
							</Text>
							<Button
								variant="orangeSolid"
								minW="120px"
								type="submit"
								isLoading={isLoading}
								loadingText="Loading..."
							>
								Sign Up
							</Button>
						</Flex>
					</Flex>
				</form>
			</FormProvider>
		</Flex>
	);
};

export default SignUpForm;
