import { z } from 'zod';
import {
	ECampaignPromotedType,
	ECampaignScheduleType,
	ICampaignBudgetBreakdown,
	ICampaignGoal,
	ICampaignPromotion,
	ICampaignSchedule,
} from './campaign';
import { IOption, OptionSchema } from '../misc';

interface ICampaignBudget {
	currency?: string;
	total: string;
	breakdown?: Array<ICampaignBudgetBreakdown>;
}

interface ICampaignPromotedObject {
	type: ECampaignPromotedType;
	value: Array<IOption>;
	ids?: [];
}

export interface ICampaignForm {
	group?: string;
	title: string;
	description: string;
	goal?: Array<ICampaignGoal>;
	goalsList?: Array<string>;
	destination?: string;
	audience: Array<string>;
	tone: Array<string>;
	promotedObject: ICampaignPromotedObject;
	promotion?: Array<ICampaignPromotion>;
	channels: string[];
	budget: ICampaignBudget;
	placements?: string[];
}

const CampaignGoal: Zod.ZodType<ICampaignGoal> = z.object({
	id: z.string().nonempty('Goals id is required'),
	target: z.union([z.string(), z.number()]).optional(),
});

const CampaignPromotedObject: Zod.ZodType<ICampaignPromotedObject> = z
	.object({
		type: z.nativeEnum(ECampaignPromotedType),
		value: z.array(OptionSchema).default([]),
	})
	.superRefine(({ type, value }, ctx) => {
		if (type !== ECampaignPromotedType.landingPage) {
			if (!value || value.length === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'One of the options must be selected',
					path: ['value'],
				});
			}
		}
	}) as Zod.ZodType<ICampaignPromotedObject>;

const CampaignPromotion: Zod.ZodType<ICampaignPromotion> = z.object({
	code: z.string().nonempty('Code is required'),
	description: z.string().nonempty('Description is required'),
});

const CampaignBudget: Zod.ZodType<ICampaignBudget> = z.object({
	total: z
		.string({
			errorMap: () => ({
				message: 'You must enter a value that is 0 or greater',
			}),
		})
		.nonempty('You must enter a value that is 0 or greater'),
	breakdown: z
		.array(
			z.object({
				id: z.string().nonempty('Id is required'),
				type: z.string().nonempty('Type is required'),
				budget: z
					.number()
					.nonnegative('You must enter a value that is 0 or greater'),
			}),
		)
		.optional(),
});

export const CampaignFormSchema: Zod.ZodType<ICampaignForm> = z.object({
	title: z.string().nonempty('Title is required'),
	description: z.string().nonempty('Description is required'),
	goal: z
		.array(CampaignGoal, {
			errorMap: () => ({
				message: 'Goals must be selected',
			}),
		})
		.min(0)
		.optional(),
	goalsList: z
		.array(z.string(), {
			errorMap: () => ({
				message: 'Goals must be selected',
			}),
		})
		.min(0)
		.optional(),
	promotedObject: CampaignPromotedObject,
	destination: z.string().optional(),
	audience: z
		.array(z.string(), {
			errorMap: () => ({
				message: 'One of the options must be selected',
			}),
		})
		.nonempty('Audience must be selected'),
	tone: z
		.array(z.string(), {
			errorMap: () => ({
				message: 'One of the options must be selected',
			}),
		})
		.nonempty('Tone must be selected'),
	promotion: z.array(CampaignPromotion).optional(),
	channels: z.array(z.string()),
	budget: CampaignBudget,
	placements: z
		.array(z.string())
		.min(1, { message: 'One of the options must be selected' })
		.optional(),
});

export const CampaignScheduleSchema: Zod.ZodType<ICampaignSchedule> = z
	.object({
		type: z.nativeEnum(ECampaignScheduleType),
		startDate: z.date({
			errorMap: () => ({ message: 'Start Date is required' }),
		}),
		endDate: z.date().optional(),
	})
	.refine(
		({ type, endDate }) => {
			const isValid =
				(type === ECampaignScheduleType['One-off'] && endDate) ||
				type === ECampaignScheduleType['Evergreen'];
			return isValid;
		},
		{
			message: 'End Date is required',
			path: ['endDate'],
		},
	)
	.refine(
		({ startDate, endDate, type }) => {
			if (type === ECampaignScheduleType['Evergreen']) return true;
			if (!startDate || !endDate) return false;
			const startDateOnly = new Date(startDate).getTime();
			const endDateOnly = new Date(endDate).getTime();
			return startDateOnly < endDateOnly;
		},
		{
			message: 'End Date should be greater than Start Date',
			path: ['endDate'],
		},
	);
