import config from 'src/config';

interface OauthUrlParams {
	account: string;
	redirectTo: string;
}

export const getFacebookOauthUrl = (state: Record<string, string>) => {
	const rootUrl = 'https://www.facebook.com/v20.0/dialog/oauth';
	const options = {
		client_id: config.facebook.clientId,
		config_id: config.facebook.userConfigId,
		redirect_uri: `${config.app.apiURL}/integrations/facebook/callback`,
		scope: config.facebook.scope,
		response_type: 'code',
		state: JSON.stringify(state),
	};

	const qs = new URLSearchParams(options).toString();
	return `${rootUrl}?${qs}`;
};

export const getGoogleOauthUrl = ({ account, redirectTo }: OauthUrlParams) => {
	const rootUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
	const options = {
		client_id: config.google.clientId,
		redirect_uri: `${config.app.apiURL}/integrations/google/callback`,
		scope:
			'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/adwords',
		response_type: 'code',
		state: JSON.stringify({ account, redirectTo }),
		access_type: 'offline',
		prompt: 'consent',
	};

	const qs = new URLSearchParams(options).toString();
	return `${rootUrl}?${qs}`;
};

export const getTwitterOauthUrl = (account: string) => {
	const rootUrl = 'https://twitter.com/i/oauth2/authorize';
	const options = {
		redirect_uri: `${
			config.app.apiURL
		}/integrations/twitter/callback?account=${encodeURIComponent(account)}`,
		client_id: config.twitter.clientId,
		state: 'state',
		response_type: 'code',
		code_challenge: 'y_SfRG4BmOES02uqWeIkIgLQAlTBggyf_G7uKT51ku8',
		code_challenge_method: 'S256',
		scope: ['users.read'].join(' '),
	};
	const qs = new URLSearchParams(options).toString();
	return `${rootUrl}?${qs}`;
};
