import {
	IGetThreadParams,
	IThreadResponse,
	IThreadMessageParams,
	IMessageResponse,
} from 'src/lib/schemas';
import http from './http';
import { omit } from 'lodash';
import config from 'src/config';

const apiVersion = 'v1';
const assistantURL = 'assistants';
const asst_review_card = config.app.assistantId;

export const startThread = async (
	threadId?: string,
	userMessage: string = 'Hi',
) => {
	const url = `/${apiVersion}/${assistantURL}/${asst_review_card}/thread${
		threadId ?? ''
	}`;
	const body = {
		user_message: userMessage,
	};
	const { data } = await http.post<IMessageResponse>(url, body);
	return data;
};

export const getAsyncMessageUrl = ({ threadId, messageId }: any) => {
	const url = `/${apiVersion}/threads/${threadId}/messages/${messageId}`;
	return url;
};

export const getThreadById = async (id: string) => {
	const threadUrl = `/${apiVersion}/threads/${id}`;
	const { data } = await http.get<IThreadResponse>(threadUrl);
	return data;
};

export const getThreadByReference = async ({
	type,
	reference,
	sub_reference,
	property,
}: IGetThreadParams) => {
	const threadUrl = `/${apiVersion}/threads?type=${type}&reference=${reference}&sub_reference=${sub_reference}&property=${property}`;
	const { data } = await http.get<IThreadResponse>(threadUrl);
	return data;
};

export const sendMessage = async ({
	assistantId,
	payload,
	threadId,
}: IThreadMessageParams) => {
	const threadIdUrlParam = threadId ? '/' + threadId : '';
	const threadUrl = `/${apiVersion}/${assistantURL}/${asst_review_card}/thread${threadIdUrlParam}`;
	const { data } = await http.post<IMessageResponse>(
		threadUrl,
		omit(payload, 'thread_id'),
	);
	return data;
};
