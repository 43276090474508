import React, { useState } from 'react';
import {
	Box,
	Flex,
	Input,
	Tag,
	TagLabel,
	TagCloseButton,
	Text,
} from '@chakra-ui/react';
import {
	useController,
	useFormContext,
	FieldPath,
	FieldValues,
} from 'react-hook-form';

interface TagInputHookProps<T extends FieldValues> {
	label: string;
	name: FieldPath<T>;
	placeholder?: string;
	requirementsLabel?: string;
	required?: boolean;
}

function TagInputHook<T extends FieldValues>({
	label,
	name,
	placeholder = 'Add a keyword',
	requirementsLabel,
	required = false,
}: TagInputHookProps<T>) {
	const {
		control,
		formState: { errors },
		setError,
		clearErrors,
	} = useFormContext();
	const { field } = useController({ name, control });
	const [inputValue, setInputValue] = useState('');

	const tags = (field.value ?? []) as string[];

	const handleAddTag = () => {
		if (inputValue.trim() && !tags.includes(inputValue)) {
			const newTags = [...tags, inputValue.trim()];
			field.onChange(newTags);
			setInputValue('');

			if (required && newTags.length > 0) {
				clearErrors(name);
			}
		}
	};

	const handleRemoveTag = (tag: string) => {
		const newTags = tags.filter((t) => t !== tag);
		field.onChange(newTags);

		if (required && newTags.length === 0) {
			setError(name, { type: 'required', message: `${label} is required` });
		}
	};

	const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			handleAddTag();
		}
	};

	const handleInputBlur = () => {
		handleAddTag();
	};

	const error = errors[name];
	const errorMessage =
		typeof error === 'object' && error !== null && 'message' in error
			? error.message
			: null;

	return (
		<Box w="full">
			<Text fontWeight="bold" mb={1}>
				{label}{' '}
				{required && (
					<Text as="span" color="red.500">
						*
					</Text>
				)}
			</Text>
			{requirementsLabel && (
				<Text fontSize="sm" color="gray.500" mb={1}>
					{requirementsLabel}
				</Text>
			)}
			<Flex
				wrap="wrap"
				alignItems="center"
				gap={2}
				p={2}
				borderWidth={1}
				borderRadius="md"
				borderColor="gray.200"
			>
				{tags.map((tag, index) => (
					<Tag key={index} size="md" colorScheme="gray" borderRadius="full">
						<TagLabel>{tag}</TagLabel>
						<TagCloseButton onClick={() => handleRemoveTag(tag)} />
					</Tag>
				))}
				<Input
					value={inputValue}
					onChange={(e) => setInputValue(e.target.value)}
					onKeyDown={handleInputKeyDown}
					onBlur={handleInputBlur}
					placeholder={placeholder}
					variant="unstyled"
					width="auto"
					minW="100px"
					flex={1}
				/>
			</Flex>
			{errorMessage && (
				<Text color="red.500" fontSize="sm" mt={1}>
					{errorMessage as string}
				</Text>
			)}
		</Box>
	);
}

export default TagInputHook;
