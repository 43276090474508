import {
	FC,
	ReactNode,
	forwardRef,
	useState,
	useRef,
	RefObject,
	useEffect,
} from 'react';
import {
	Flex,
	Text,
	FormControl,
	FormControlProps,
	FormLabel,
	FormLabelProps,
	FormErrorMessage,
	Skeleton,
	VStack,
	RadioGroup,
	Stack,
	Radio,
} from '@chakra-ui/react';
import {
	ArrowForwardIcon,
	ChevronDownIcon,
	InfoIcon,
	SearchIcon,
} from '@chakra-ui/icons';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {
	ControlProps,
	DropdownIndicatorProps,
	GroupBase,
	OptionProps,
	SingleValueProps,
	StylesConfig,
	components,
} from 'react-select';

import { useProductFormModalContext } from 'src/contexts/ProductFormModalContext';

import { IOption } from 'src/lib/schemas/misc';
import { ECampaignPromotedType } from 'src/lib/schemas';

export interface MultiSelectCreateableInputProps {
	name: string;
	label?: string;
	requirementsLabel?: string;
	id?: string;
	placeholder?: string;
	error?: ReactNode;
	required?: boolean;
	options: IOption[];
	value?: IOption['value'][] | IOption[];
	onChange?: (
		val: IOption['value'][] | IOption[],
		generateByAI?: boolean,
	) => void;
	onCreateOption?: (val: string) => void;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	selectOnlyOne?: boolean;
	isClearable?: boolean;
	valueAsObject?: boolean;
	onValueChangeCallback?: (
		val: IOption['value'][] | IOption[],
		generateByAI?: boolean,
	) => void;
	isLoading?: boolean;
	valuePrefix?: string;
	isMulti?: boolean;
	disableCreateOption?: boolean;
	onOpenMenu?: (ref: RefObject<HTMLDivElement>) => void;
	direction?: 'row' | 'column';
	isProductInput?: boolean;
	promotedObjectType?: ECampaignPromotedType;
	requirementsLabelStyle?: React.CSSProperties;
	shouldShowSkeleton?: boolean;
}

const Control: FC<ControlProps> = ({ children, ...props }) => (
	<components.Control {...props}>
		<SearchIcon mx={2} color="#B8C2D0" /> {children}
	</components.Control>
);

const Option: FC<OptionProps> = ({ children, ...props }) => (
	<components.Option {...props}>
		<Flex justify="space-between">
			{children}
			<ArrowForwardIcon boxSize={4} color="#2C6ECB" alignSelf="right" />
		</Flex>
	</components.Option>
);

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<ChevronDownIcon w={5} h={5} />
		</components.DropdownIndicator>
	);
};

const MultiSelectCreateableInput = (
	{
		label,
		requirementsLabel,
		requirementsLabelStyle,
		placeholder,
		options = [],
		value = [],
		onChange,
		onCreateOption,
		required,
		error,
		formControlProps,
		formLabelProps,
		isClearable,
		valueAsObject,
		isLoading,
		valuePrefix,
		isMulti,
		disableCreateOption,
		direction = 'column',
		onOpenMenu,
		isProductInput,
		promotedObjectType,
		shouldShowSkeleton = true,
	}: MultiSelectCreateableInputProps,
	ref: any,
) => {
	const [currentText, setCurrentText] = useState('');
	const inputRef = useRef<HTMLDivElement>(null);
	const hasError = !!error;
	const elements = document.querySelectorAll('[class*="container"]');
	const [radioGroupValue, setRadioGroupValue] = useState('no');

	elements.forEach((element) => {
		(element as HTMLElement).style.width = '100%';
	});

	useEffect(() => {
		isProductInput &&
			setRadioGroupValue(
				promotedObjectType === ECampaignPromotedType.catalogs ? 'yes' : 'no',
			);
	}, [promotedObjectType, isProductInput]);

	const renderLabelIndicator = () => {
		if (!required) return null;
		if (error) return <InfoIcon color="#e53e3e" ml="3px" />;
		return (
			<Text color="#e53e3e" ml="2px">
				*
			</Text>
		);
	};

	const handleRadioChange = (value: string) => {
		setRadioGroupValue(value);
		if (value === 'no') {
			onChange && onChange(selectedValue, true);
		} else if (value === 'yes') {
			onChange && onChange(selectedValue, false);
		}
	};

	const productFormModalContext = useProductFormModalContext();
	const isProductFormModal = productFormModalContext?.isProductFormModal;

	const selectedValue = valueAsObject
		? value
		: (value?.map((v) =>
				options.find((o) => o.value === v?.toString()),
		  ) as IOption[]);

	const SelectComponent = disableCreateOption ? Select : CreatableSelect;

	return (
		<FormControl isInvalid={hasError} {...formControlProps}>
			<Flex direction={direction} w="full" gap={direction === 'row' ? 20 : 0}>
				<Flex flex={1}>
					{label &&
						(isProductFormModal ? (
							<Flex
								direction="row"
								justify="space-between"
								alignItems="center"
								w="100%"
							>
								<FormLabel
									mb="0"
									display="flex"
									alignItems="center"
									{...formLabelProps}
								>
									{label}
									{renderLabelIndicator()}
								</FormLabel>
								{requirementsLabel && (
									<Text textAlign="right" fontSize="12px" color="#959595">
										{requirementsLabel}
									</Text>
								)}
							</Flex>
						) : (
							<FormLabel mb="0" {...formLabelProps}>
								<Flex gap={1} alignItems="center">
									{label}
									{renderLabelIndicator()}
								</Flex>
								{requirementsLabel && (
									<Flex>
										<Text
											fontSize="12px"
											color="#959595"
											style={requirementsLabelStyle}
										>
											{requirementsLabel}
										</Text>
									</Flex>
								)}
							</FormLabel>
						))}
				</Flex>
				<VStack
					data-testid="select-component"
					flex={1}
					w="full"
					alignItems="flex-start"
				>
					{isLoading && shouldShowSkeleton ? (
						<Flex direction="column" w="full">
							<Skeleton h="40px" borderRadius="5px" />
							{isMulti && (
								<Flex gap={3}>
									<Skeleton h="32px" borderRadius="full" my={2} flex={1} />
									<Skeleton h="32px" borderRadius="full" my={2} flex={1} />
									<Skeleton h="32px" borderRadius="full" my={2} flex={1} />
								</Flex>
							)}
						</Flex>
					) : (
						<Flex direction="column" ref={inputRef} width="100%">
							{isProductInput && (
								<Flex direction="column" mb={4}>
									<RadioGroup
										value={radioGroupValue}
										onChange={handleRadioChange}
									>
										<Stack direction="column">
											<Radio data-testid="radio-input" value="no">
												<Text fontSize="14px" color="black">
													No, let AI generate an image for you
												</Text>
											</Radio>
											<Radio data-testid="radio-input" value="yes">
												<Text fontSize="14px" color="black">
													Yes
												</Text>
											</Radio>
										</Stack>
									</RadioGroup>
								</Flex>
							)}
							{radioGroupValue === 'yes' || !isProductInput ? (
								<SelectComponent
									onInputChange={(e) => setCurrentText(e)}
									onKeyDown={(e) => {
										if (e.key === 'Backspace' || e.key === 'Delete') {
											if (currentText === '') {
												e.preventDefault();
											}
										}
									}}
									onMenuOpen={() => onOpenMenu && onOpenMenu(inputRef)}
									isValidNewOption={() => !disableCreateOption}
									formatCreateLabel={(value) => (
										<Text fontWeight={500}>
											{value ? `Create "${value}"` : 'Create a new one'}
										</Text>
									)}
									placeholder={placeholder}
									options={options ?? []}
									value={selectedValue || ''}
									createOptionPosition="first"
									onCreateOption={
										disableCreateOption ? undefined : onCreateOption
									}
									onChange={(val) => {
										if (!onChange) return;
										if (isMulti) {
											const valueSelected = val as IOption[];
											if (valueAsObject) {
												onChange(valueSelected);
												return;
											} else {
												onChange(valueSelected.map((v) => v.value));
											}
										} else {
											const valueSelected = val as IOption;
											if (valueAsObject) {
												if (!valueSelected) {
													onChange([]);
												} else {
													onChange([valueSelected]);
												}
											} else {
												if (!valueSelected) {
													onChange([]);
												} else {
													onChange([valueSelected.value]);
												}
											}
										}
									}}
									components={{
										DropdownIndicator,
										Control,
										Option,
										SingleValue: ({ children, ...props }: SingleValueProps) => {
											return (
												<components.SingleValue {...props}>
													{valuePrefix}
													{children}
												</components.SingleValue>
											);
										},
									}}
									controlShouldRenderValue={!isMulti}
									styles={{
										...styles,
										...(hasError
											? stylesWithErros
											: { control: styles.control }),
										...(isMulti ? stylesHideClearIcon : {}),
									}}
									ref={ref}
									isLoading={isLoading}
									isClearable={isClearable}
									noOptionsMessage={() => `No element found`}
									isMulti={isMulti}
								/>
							) : (
								<div></div>
							)}
						</Flex>
					)}
					{error && <FormErrorMessage mt={0}>{error}</FormErrorMessage>}
				</VStack>
			</Flex>
		</FormControl>
	);
};

const styles: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
	control: (baseStyles, state) => ({
		...baseStyles,
		padding: 0,
		minHeight: '40px',
		height: '40px',
		borderColor: '#E2E8F0',
		borderRadius: '5px',
		marginTop: '9px',
		boxShadow: state.isFocused ? '0 0 0 1px #2C6ECB' : 'none',
		border: state.isFocused ? '1px solid #2C6ECB' : '1px solid #E2E8F0',
	}),
	valueContainer: (baseStyles) => ({
		...baseStyles,
		minHeight: '40px',
		height: '40px',
		padding: 0,
		paddingRight: '10px',
		fontSize: '14px',
		lineHeight: '20px',
		color: '#202223',
	}),
	input: (baseStyles) => ({
		...baseStyles,
		margin: 0,
		fontSize: '14px',
		lineHeight: '20px',
		color: '#202223',
		padding: 0,
	}),
	placeholder: (baseStyles) => ({
		...baseStyles,
		fontSize: '14px',
		lineHeight: '20px',
		color: '#A0AEC0',
	}),
	indicatorsContainer: (baseStyles) => ({
		...baseStyles,
		padding: '0 10px',
	}),
	dropdownIndicator: () => ({
		color: '#202223',
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	option: (baseStyles, { isSelected, isFocused }) => {
		let backgroundColor = 'inherit';
		if (isFocused) backgroundColor = '#f9f9fa';
		if (isSelected) backgroundColor = '#E2E8F0';

		return {
			...baseStyles,
			fontSize: '14px',
			lineHeight: '20px',
			color: '#202223',
			padding: '8px 16px 8px 16px',
			borderBottom: '1px solid #E2E8F0',
			backgroundColor,
			cursor: 'pointer',
		};
	},
};

const stylesWithErros: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
	control: (baseStyles) => ({
		...baseStyles,
		padding: 0,
		minHeight: '40px',
		height: '40px',
		borderRadius: '5px',
		boxShadow: '0 0 0 1px #E53E3E',
		borderColor: '#E53E3E',
		'&:hover': {
			boxShadow: '0 0 0 1px #E53E3E',
			borderColor: '#E53E3E',
		},
	}),
};

const stylesHideClearIcon: StylesConfig<
	unknown,
	boolean,
	GroupBase<unknown>
> = {
	clearIndicator: () => ({
		display: 'none',
	}),
};

export default forwardRef(MultiSelectCreateableInput);
