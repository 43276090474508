import { FC, useContext, useEffect, useState } from 'react';
import { Button, Flex, Text, Tooltip } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { isArray } from 'lodash';
import { DownloadIcon, UploadIcon } from 'src/assets/icons';
import { CampaignContext } from 'src/contexts';
import {
	IChannelMediaAttributes,
	IDesignDirection,
	ImageLayer,
	TextLayer,
} from 'src/lib/schemas';
import { customToast, toastError } from 'src/services/toast';
import { parseCampaignName } from 'src/lib/utils';
import config from 'src/config';
import auth from 'src/services/auth';
import axios from 'axios';
import http from 'src/services/http';
import { updateCampaignCreative } from 'src/services/campaign';
import PhotoEditorPreview, { IIframeStyle } from './PhotoEditorPreview';
import { ICreative } from 'src/lib/schemas/campaign/newFlowCampaign';

export interface DDPhotoEditorProps {
	layeredFile: any;
	textLayersContent?: any;
	mediaLayersContent?: any;
	fonts?: string[];
	designDirection: IDesignDirection | ICreative;
	isChangingLayer: boolean;
	onRefetchCreatives: () => void;
	withActions?: boolean;
	containerWidth?: string;
	containerHeight?: string;
	iframeStyle?: IIframeStyle;
	resizeScript?: string;
}

interface Variables {
	[key: string]: string;
}

const findVariables = (layers: (ImageLayer | TextLayer)[]): Variables => {
	const variables: Variables = {};
	layers.forEach((layer) => {
		const l = layer as ImageLayer;
		if (l.type === 'image' && l.imageUrl) {
			variables[l.name] = l.imageUrl;
		}
	});
	return variables;
};
const DDPhotoEditor: FC<DDPhotoEditorProps> = ({
	layeredFile,
	textLayersContent,
	mediaLayersContent,
	fonts,
	designDirection,
	isChangingLayer,
	onRefetchCreatives,
	withActions = true,
	containerWidth,
	containerHeight,
}) => {
	const { id: campaignId, campaign } = useContext(CampaignContext);
	const {
		id: creativeId,
		variant,
		locked,
		attributes,
		channel,
	} = designDirection;
	const attributesData = attributes as unknown as IChannelMediaAttributes;
	const {
		dimensions: { width, height },
	} = attributesData.image;

	const [isEditorLoading, setIsEditorLoading] = useState(true);
	const [showOverlay, setShowOverlay] = useState(true);
	const [documentCount, setDocumentCount] = useState(0);
	const [peaKey, setPeaKey] = useState(0);
	const [isDownloading, setIsDownloading] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showPendingProgress, setShowPendingProgress] = useState(false);
	const [isUploadingFile, setIsUploadingFile] = useState(false);
	const [fileToSave, setFileToSave] = useState<ArrayBuffer | null>(null);
	const [isTextLoaded, setIsTextLoaded] = useState(false);

	const [isSubmitted, setIsSubmitted] = useState(false);
	// const [error, setError] = useState<string | null>(null);

	const isInvalidNumberOfDocuments = documentCount !== 1;

	useEffect(() => {
		if (showOverlay && !isInvalidNumberOfDocuments) setShowOverlay(false);
	}, [documentCount, showOverlay]);

	const handleTriggerPSDDownload = async () => {
		const psdDownloadButtonElem = document.getElementById(
			'psd-preview-download-button',
		);
		if (psdDownloadButtonElem) psdDownloadButtonElem.click();
	};
	const handleDownload = async (ab: ArrayBuffer) => {
		setIsDownloading(true);
		const fileBlob = new Blob([ab]);
		if (!fileBlob) {
			customToast('No file to download', 'error');
			return;
		}

		const fileName = `${parseCampaignName(
			campaign?.title ?? '',
		)}-${variant}-${channel}-${width}x${height}`;
		const link = document.createElement('a');
		link.href = URL.createObjectURL(fileBlob);
		link.setAttribute('download', `${fileName}.psd`);
		document.body.appendChild(link);
		link.click();
		link?.parentNode?.removeChild(link);
		setIsDownloading(false);
	};

	const handleFailedUpdating = () => {
		setFileToSave(null);
		setIsUploadingFile(false);
		setIsSubmitting(false);
		setPeaKey((prev) => prev + 1);
		setIsTextLoaded(false);
	};

	useEffect(() => {
		if (isUploadingFile || !fileToSave) return;
		handleUploadFile(fileToSave);
		setIsUploadingFile(true);
	}, [isUploadingFile, fileToSave]);

	useEffect(() => {
		if (isSubmitting && !isEditorLoading) setShowPendingProgress(true);
	}, [isSubmitting, isEditorLoading]);

	const handleUploadFile = async (ab: ArrayBuffer) => {
		setIsSubmitted(true);
		if (isInvalidNumberOfDocuments) return;
		if (!campaignId || !creativeId) return;
		setIsSubmitting(true);

		const fileBlob = new Blob([ab]);
		const formData = new FormData();
		formData.append('f', fileBlob, `${new Date().getTime()}.psd`);

		const baseURL = config.app.apiURL.split('/v1')[0];
		const uploadUrl = `${baseURL}/v1/user-storage/upload?fileExtension=psd&directory=edit-image`;
		const tokens = auth.getTokens();
		const headers = {
			'Content-Type': `multipart/form-data`,
			Authorization: `Bearer ${tokens?.access.token}`,
		};

		axios({
			method: 'post',
			url: uploadUrl,
			data: formData,
			headers,
		})
			.then(({ data }) => {
				handleGenerateManifest(data);
			})
			.catch((error) => {
				toastError(error);
				handleFailedUpdating();
			});
	};

	const handleGenerateManifest = async (layeredFile: string) => {
		const variables = findVariables(attributesData.image.layers);
		http
			.post('/v2/apps/fusion_ai.generate_image_psd/execute/process_psd', {
				inputs: {
					layeredFile,
					variables,
					templateId: creativeId,
					variantId: variant,
					newPsd: 'true',
				},
			})
			.then(({ data }) => {
				processCallback(data.callback);
			})

			.catch((error) => {
				toastError(error);
				handleFailedUpdating();
			});
	};

	const processCallback = async (callbackUrl: string) => {
		try {
			const response = await http.get(callbackUrl);
			const { status, body } = response.data;
			if (status === 'processing' || status === 'pending') {
				setTimeout(processCallback.bind(null, callbackUrl), 500);
			} else if (status === 'error' || status === 'failed') {
				console.error(designDirection);
				customToast(JSON.stringify(designDirection), 'error');
				handleFailedUpdating();
			} else if (status === 'successful') {
				// const currentLayersNames = body.layers.map((layer: any) => layer.name);
				// const isLayersValid = LAYERS_NAMES.every((layerName) =>
				// 	currentLayersNames.includes(layerName),
				// );
				// if (!isLayersValid) {
				// 	setError(
				// 		'You must have "Background", "Product", "Headline" layers in your PSD file.',
				// 	);
				// 	handleFailedUpdating();
				// 	return;
				// }
				// if (
				// 	Number(width) !== body.dimensions.width ||
				// 	Number(height) !== body.dimensions.height
				// ) {
				// 	setError(
				// 		`Your image doesn't match the dimensions ${width}x${height}`,
				// 	);
				// 	handleFailedUpdating();
				// 	return;
				// }
				const updatePayload = {
					attributes: {
						...attributesData,
						image: {
							dimensions: {
								width: String(body.dimensions.width),
								height: String(body.dimensions.height),
							},
							layeredFile: body.layeredFile,
							flatFile: body.flatFile,
							layers: body.layers,
						},
					},
				};
				if (!campaignId || !creativeId) {
					customToast('Campaign or creative ID is missing', 'error');
					handleFailedUpdating();
					return;
				}

				await updateCampaignCreative(campaignId, creativeId, updatePayload);
				await onRefetchCreatives();
				setIsSubmitting(false);
				setIsUploadingFile(false);
			}
		} catch (e) {
			toastError(e);
		}
	};

	return (
		<>
			{withActions && (
				<Flex minW="430px" alignItems="center" justify="space-between">
					<Flex>
						<Text size="md">Preview</Text>
					</Flex>
					<Flex gap={2} alignItems="center">
						<Tooltip label="Download" borderRadius={100} px={3} zIndex="2000">
							<Button
								variant="blankOutline"
								px={0}
								h={8}
								size="sm"
								isDisabled={isEditorLoading || isSubmitting}
								onClick={handleTriggerPSDDownload}
								isLoading={isDownloading}
							>
								<DownloadIcon color="black" />
							</Button>
						</Tooltip>
						<Tooltip label="Upload" borderRadius={100} px={3} zIndex="2000">
							<Button
								variant="blankOutline"
								htmlFor="file-input"
								as="label"
								px={0}
								h={8}
								size="sm"
								cursor="pointer"
								isDisabled={
									isDownloading ||
									isEditorLoading ||
									isSubmitting ||
									isChangingLayer
								}
							>
								<UploadIcon color="black" />
							</Button>
						</Tooltip>
					</Flex>
				</Flex>
			)}
			<PhotoEditorPreview
				key={peaKey}
				input={{ files: [layeredFile] }}
				isEditorLoading={isEditorLoading}
				showOverlay={isChangingLayer}
				onLoadingFinished={() => setIsEditorLoading(false)}
				onDocumentCount={(count) => setDocumentCount(count)}
				textLayersContent={textLayersContent}
				mediaLayersContent={mediaLayersContent}
				onDownload={handleDownload}
				onSave={(ab) => setFileToSave(ab)}
				isSubmitting={isSubmitting}
				showPendingProgress={showPendingProgress}
				onPendingProgressFinished={(val) => setShowPendingProgress(val)}
				fonts={fonts}
				containerWidth={containerWidth}
				containerHeight={containerHeight}
			/>
		</>
	);
};

export default DDPhotoEditor;
