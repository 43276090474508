import React, {
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
} from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
	Button,
	Image,
	Text,
	FormControl,
	VStack,
	Box,
	Flex,
	FormErrorMessage,
} from '@chakra-ui/react';
import {
	FileInput,
	SelectSearchInputHook,
	StringInputHook,
	TextareaInputHook,
} from '../common/form';
import { zodResolver } from '@hookform/resolvers/zod';
import AccountSettingsSchema, {
	IAccountSettingsForm,
	PartialAccountSettingsSchema,
} from 'src/lib/schemas/account/settings';
import UserContext from 'src/contexts/UserContext';
import FusionLoading from '../common/FusionLoading';
import { FileRejection } from 'react-dropzone';
import { uploadFile } from 'src/services/fileUpload';
import useAccountConfig from 'src/hooks/useAccountConfig';
import MultiLevelDropdown from '../common/MultiLevelDropdown';
import { isEmpty, omit } from 'lodash';
import { IIndustry, getIndustries } from 'src/services/industries';
import TagInputHook from '../common/TagInput';

const IMAGE_NOT_VALID_MESSAGE = 'Invalid image';

interface AccountSettingsFormProps {
	onSubmit: (formData: Partial<IAccountSettingsForm>) => void;
	fields?: Partial<IAccountSettingsForm>;
}

const AccountSettingsForm = forwardRef(
	({ fields, onSubmit }: AccountSettingsFormProps, ref) => {
		const [isLogoLoading, setIsLogoLoading] = useState(false);
		const [industries, setIndustries] = useState<IIndustry[] | null>(null);
		const [loadingIndustries, setLoadingIndustries] = useState(true);
		const { config, isLoading: isConfigLoading } = useAccountConfig();
		const { account, user } = useContext(UserContext);

		const defaultAccountSettings: Partial<IAccountSettingsForm> = {
			websiteLink: fields?.websiteLink ?? account?.websiteLink,
			name: fields?.name ?? account?.name ?? '',
			description: fields?.description ?? account?.description ?? '',
			industry: !isEmpty(fields?.industry)
				? omit(fields?.industry, 'industryId', 'parentId', 'entityType', 'id')
				: account?.industry,
			keywords: fields?.keywords ?? account?.keywords ?? [],
			logo: fields?.logo ?? account?.logo ?? '',
			brandIdentity: {
				fontType: fields?.brandIdentity?.fontType ?? account?.font,
			},
			account: user?.account ?? '',
		};

		useEffect(() => {
			user?.account && setValue('account', user.account);
		}, [user?.account]);

		const formMethods = useForm<Partial<IAccountSettingsForm>>({
			resolver: zodResolver(PartialAccountSettingsSchema),
			defaultValues: defaultAccountSettings,
		});

		const {
			handleSubmit,
			formState,
			setError: setFieldError,
			clearErrors,
			setValue,
			watch,
			trigger,
		} = formMethods;

		const fontType = watch('brandIdentity.fontType');
		const logo = watch('logo');
		const industry = watch('industry');

		const logoHasError = formState.errors['logo'];
		const memoizedLogoError = useMemo(
			() => logoHasError?.message,
			[logoHasError],
		);

		useImperativeHandle(ref, () => ({
			validateForm: async () => {
				return await trigger();
			},
		}));

		const fetchIndustries = async () => {
			setLoadingIndustries(true);
			const response = await getIndustries();
			response && setIndustries(response);
			setLoadingIndustries(false);
		};

		useEffect(() => {
			fetchIndustries();
		}, []);

		useEffect(() => {
			if (memoizedLogoError !== undefined) {
				setFieldError('logo', { message: memoizedLogoError });
			}
		}, [memoizedLogoError, setFieldError]);

		const onImageError = (message?: string) => {
			setFieldError('logo', { message });
			console.error('error', message);
		};

		const handleLogoChange = async (
			input: File | string,
			fileRejections?: FileRejection[],
		) => {
			const minWidth = 100;
			const minHeight = 30;

			if (fileRejections && fileRejections.length > 0) {
				console.error('File rejected', fileRejections);
				onImageError('File type not accepted');
				return;
			}

			setIsLogoLoading(true);
			try {
				const { imageUrl } = await uploadFile(input, true, minWidth, minHeight);
				setIsLogoLoading(false);
				clearErrors('logo');
				setValue('logo', imageUrl);
			} catch (error: any) {
				console.error('Error uploading file', error);
				onImageError(error.response?.data?.message || IMAGE_NOT_VALID_MESSAGE);
				setIsLogoLoading(false);
			}
		};

		const handleTypographyChange = (url: string) => {
			setValue('brandIdentity.fontType', url);
		};

		const handleFontChange = async (
			input: File | string,
			fileRejections?: FileRejection[],
		) => {
			if (fileRejections && fileRejections.length > 0) {
				console.error('File rejected', fileRejections);
				onImageError('File type not accepted');
				return;
			}
			const newFont = typeof input === 'string' ? input : (input as any).path;
			setValue('brandIdentity.fontType', newFont);
		};

		const handleIndustryChange = (industry: IIndustry) => {
			setValue('industry', omit(industry, 'children', 'parentId', '_id'));
		};

		const renderField = (fieldName: keyof IAccountSettingsForm) => {
			switch (fieldName) {
				case 'websiteLink':
					return <StringInputHook name="websiteLink" label="Website" />;
				case 'name':
					return <StringInputHook name="name" label="Business name" required />;
				case 'description':
					return (
						<TextareaInputHook
							name="description"
							label="Description of your business"
							required
						/>
					);
				case 'industry':
					return (
						<MultiLevelDropdown
							onSelect={handleIndustryChange}
							items={industries}
							initialValue={industry}
							label="Industry"
							isLoading={loadingIndustries}
						/>
					);
				case 'keywords':
					return (
						<TagInputHook
							label="Keywords"
							name="keywords"
							placeholder="Add a keyword"
							required
						/>
					);

				case 'logo':
					return (
						<Flex gap="20px" w="full">
							<FormControl isInvalid={Boolean(logoHasError)}>
								<StringInputHook
									name="logo"
									label="Logo"
									placeholder="Enter Logo URL"
									required
									withErrorMessage={false}
									errorMessage={formState.errors['logo']?.message as string}
									inputProps={{
										onChange: (e) => handleLogoChange(e.target.value),
									}}
								/>
								<FormErrorMessage>
									{formState.errors['logo']?.message}
								</FormErrorMessage>
								<Flex justify="center" py={2} alignItems="center" gap={2}>
									<Box flex={1} h="1px" bg="gray.100" />
									<Text>OR</Text>
									<Box flex={1} h="1px" bg="gray.100" />
									<Box />
								</Flex>
								<Flex justifySelf="flex-end">
									<FileInput
										name="logo"
										acceptImages
										uploadButtonText={logo ? 'Change image' : 'Upload image'}
										onUrlChange={handleLogoChange}
										uploadPath={`${user?.account}/logo`}
										onDrop={(acceptedFiles, fileRejections) =>
											handleLogoChange(acceptedFiles[0], fileRejections)
										}
										onLoading={setIsLogoLoading}
									/>
								</Flex>
							</FormControl>
							<Flex minW="150px" justify="center" align="center">
								<FusionLoading isLoading={isLogoLoading} />
								{logo && !logoHasError && !isLogoLoading && (
									<Image
										src={logo}
										alt="Account logo"
										maxW="100px"
										onError={() => onImageError()}
										objectFit="contain"
									/>
								)}
							</Flex>
						</Flex>
					);
				case 'brandIdentity':
					return (
						<Flex gap="20px" w="full">
							<FormControl>
								<SelectSearchInputHook
									name="brandIdentity.fontType"
									label="Typography"
									placeholder="Select"
									isClearable
									options={
										!isConfigLoading && config?.fonts ? config.fonts : []
									}
								/>
								<Flex justify="center" py={2} alignItems="center" gap={2}>
									<Box flex={1} h="1px" bg="gray.100" />
									<Text>OR</Text>
									<Box flex={1} h="1px" bg="gray.100" />
									<Box />
								</Flex>
								<Flex justifySelf="flex-end">
									<FileInput
										name="brandIdentity.fontType"
										acceptFonts
										uploadButtonText="Upload font"
										uploadPath={`${user?.account}/typography`}
										onUrlChange={handleTypographyChange}
										onDrop={(acceptedFiles, fileRejections) =>
											handleFontChange(acceptedFiles[0], fileRejections)
										}
									/>
								</Flex>
							</FormControl>
							<Flex minW="150px">
								{fontType && (
									<Text fontFamily="CustomFont" fontSize={18} w="150px">
										ABCDEFGHIJKLMNOPQRSTUVWXYZ
										<br />
										abcdefghijklmnopqrstuvwxyz
									</Text>
								)}
							</Flex>
						</Flex>
					);
				default:
					return null;
			}
		};

		return (
			<FormProvider {...formMethods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<VStack spacing={4} align="start">
						{/* Render all fields if `fields` is undefined; otherwise, only render fields specified in `fields`. */}
						{Object.keys(defaultAccountSettings).map((key) =>
							fields === undefined ||
							fields[key as keyof IAccountSettingsForm] !== undefined
								? renderField(key as keyof IAccountSettingsForm)
								: null,
						)}
					</VStack>
					<Box display="none" textAlign="center">
						<Button
							id="account-settings-save-button"
							onClick={async () => {
								const isFormValid = await trigger();
								if (!isFormValid) {
									return;
								}
								onSubmit(formMethods.getValues());
							}}
						></Button>
					</Box>
				</form>
			</FormProvider>
		);
	},
);

AccountSettingsForm.displayName = 'AccountSettingsForm';
export default AccountSettingsForm;
