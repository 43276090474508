import { Flex, ListItem, OrderedList, Text } from '@chakra-ui/react';
import { Step } from 'react-joyride';

export const PRODUCT_TOUR_STEPS: Step[] = [
	{
		placement: 'right',
		title: 'Fusion is here to help you to move faster',
		target: '#image-A',
		disableBeacon: true,
		content: (
			<Flex direction="column" gap={2}>
				<Text>To improve the images, try the following steps:</Text>
				<OrderedList fontSize="14px">
					<ListItem mb={1}>Upload your font</ListItem>
					<ListItem mb={1}>
						Product image should be good resolution and without background
					</ListItem>
					<ListItem mb={1}>Provide a clear background description</ListItem>
					<ListItem>Make the last refinements manually</ListItem>
				</OrderedList>
			</Flex>
		),
		locale: {
			next: 'Show me how',
		},
	},
	{
		placement: 'right',
		title: 'Upload your font',
		target: '#Account',
		content: (
			<Text>
				You can access to your account and upload your own font. We will use
				that font to generate the text.
			</Text>
		),
		locale: {
			next: 'Next',
		},
	},
	{
		placement: 'bottom-start',
		title: 'Describe your background',
		target: '#regenerate-design-direction-A',
		content: (
			<Flex direction="column" textAlign="left">
				<Text>
					Here you can regenerate the background, include a good description on
					where to show the product. You can also give details of the scene such
					as the hue, tint, shade and colours.
				</Text>
			</Flex>
		),
		locale: {
			last: 'Close',
		},
	},
	{
		placement: 'bottom-start',
		title: 'Refine it manually',
		target: '#edit-media-creative-1-1',
		content: (
			<Flex direction="column" textAlign="left">
				<Text>
					As design is not intended to be perfect, you can edit background, text
					and product position as they are separate layers, Here you can edit
					the image or you can download a .psd to adjust it with Adobe
					Photoshop. In each creative, you can also adjust the content such as
					headline, punchline, etc.
				</Text>
			</Flex>
		),
		locale: {
			last: 'Close',
		},
	},
];

export const IMAGE_PLACEHOLDER = 'https://img.clevup.in/static/placeholder.jpg';


export const SUPPORTED_CHANNELS = [
	'facebook',
	'instagram',
	'facebookPaid',
	'instagramPaid',
	'google',
];

export const BREAKPOINTS = {
	mobile: 768,
	tablet: 1024,
	desktop: 1200,
};

export const isMobile = (): boolean => window.innerWidth <= BREAKPOINTS.mobile;
export const isTablet = (): boolean =>
	window.innerWidth > BREAKPOINTS.mobile &&
	window.innerWidth <= BREAKPOINTS.tablet;
export const isDesktop = (): boolean =>
	window.innerWidth >= BREAKPOINTS.desktop;
