import { z } from 'zod';

export interface IOption {
	title?: string;
	id?: string;
	label: string;
	value: string;
	description?: string;
	supportType?: string;
	url?: string;
	targetSize?: number | string;
	image?: string;
}
export interface IPromotedObject {
	type: string;
	ids: [];
}

export const OptionSchema: Zod.ZodType<IOption> = z.object({
	title: z.string().optional(),
	label: z.string().nonempty('Label is required'),
	value: z.string().nonempty('Value is required'),
	description: z.string().optional(),
	supportType: z.string().optional(),
	targetSize: z.string().or(z.number()).optional(),
});

export interface IConfigOption {
	id?: string;
	value: string;
}

export interface IAccountConfigOption {
	id?: string;
	name: string;
}

export const AccountConfigFormSchema: Zod.ZodType<IAccountConfigOption> =
	z.object({
		id: z.string().optional(),
		name: z.string().nonempty('Name is required'),
	});
