import { useContext, useEffect } from 'react';
import AssistantChat from '../assistantChat';
import { AssistantChatContext } from 'src/contexts';
import authService from 'src/services/auth';

const GoogleCloudAssistant = () => {
	const { openGeneralAssistantChat } = useContext(AssistantChatContext);

	useEffect(() => {
		openGeneralAssistantChat();
	}, []);

	return <AssistantChat />;
};

export default GoogleCloudAssistant;
