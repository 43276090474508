import { FC } from 'react';
import { Button, Link } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { getGoogleOauthUrl } from 'src/lib/integrations';

interface GoogleAuthButtonProps {
	account: string;
}

const GoogleAuthButton: FC<GoogleAuthButtonProps> = ({ account }) => {
	const { pathname } = useLocation();

	return (
		<Link
			href={getGoogleOauthUrl({
				account,
				redirectTo: `${pathname}?fromGoogle`,
			})}
		>
			<Button colorScheme="secondary" size="sm">
				Connect
			</Button>
		</Link>
	);
};

export default GoogleAuthButton;
