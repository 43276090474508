import { FC, useContext, useEffect, useRef, useState } from 'react';
import {
	Flex,
	CloseButton,
	Text,
	Collapse,
	usePrefersReducedMotion,
	keyframes,
	HStack,
} from '@chakra-ui/react';
import { AssistantChatContext } from 'src/contexts';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { IoSparklesSharp } from 'react-icons/io5';

interface IHeader {
	fieldName?: string;
	currentContent?: string;
	height?: string | number;
	onClick: () => void;
}

const TEXT_WIDTH = 400;
const TEXT_HEIGHT = 25;

const Header: FC<IHeader> = ({
	fieldName,
	currentContent,
	height,
	onClick,
}) => {
	const { closeChat, isChatCollapsed, isCurrentChat, hasUnseenMessages } =
		useContext(AssistantChatContext);
	const [showMore, setShowMore] = useState(false);
	const [overflowActive, setOverflowActive] = useState<boolean>(false);
	const overflowingText = useRef<HTMLSpanElement | null>(null);
	const prefersReducedMotion = usePrefersReducedMotion();
	const animationParams = 'infinite 1.5s linear';
	const animation = prefersReducedMotion
		? undefined
		: `${keyframe_blink} ${animationParams}`;

	const shouldBlink = hasUnseenMessages && isCurrentChat;

	const checkOverflow = (textContainer: HTMLSpanElement | null): boolean => {
		if (textContainer) {
			return (
				textContainer.offsetWidth > TEXT_WIDTH ||
				textContainer.offsetHeight > TEXT_HEIGHT
			);
		}
		return false;
	};

	useEffect(() => {
		setOverflowActive(checkOverflow(overflowingText.current));
	}, [currentContent]);

	return (
		<>
			<Flex
				height={height}
				bg="white"
				align="center"
				p={3}
				justifyContent="space-between"
				onClick={onClick}
				cursor="pointer"
				// animation={shouldBlink ? animation : ''}
				borderBottomWidth={1}
				borderTopWidth={1}
			>
				<Flex color="orange.base" gap={2} pl={2} alignItems="center">
					<IoSparklesSharp style={{ width: '24px', height: '24px' }} />
					<Text
						color="orange.base"
						overflow="hidden"
						textOverflow="ellipsis"
						whiteSpace="nowrap"
						w={isChatCollapsed ? 250 : ''}
						fontSize="16px"
						fontWeight={500}
						mt={0.5}
					>
						Fusion Copilot {fieldName && `- ${fieldName}`}
					</Text>
				</Flex>
				<HStack>
					{isChatCollapsed ? (
						<ChevronUpIcon
							cursor="pointer"
							color="gray.700"
							fontSize="2xl"
							onClick={onClick}
						/>
					) : (
						<ChevronDownIcon
							cursor="pointer"
							color="gray.700"
							fontSize="2xl"
							onClick={onClick}
						/>
					)}
					<CloseButton size="sm" color="gray.700" onClick={closeChat} />
				</HStack>
			</Flex>
			{!isChatCollapsed && currentContent && (
				<Flex
					gap={1}
					px={3}
					py={1}
					bg="gray.300"
					borderBottomRadius={6}
					height={!showMore ? '27px' : 'auto'}
					justifyContent="space-between"
					boxShadow="0px 3px 3px -3px #cacfd3"
					zIndex={1000}
				>
					<Collapse startingHeight="27px" in={showMore}>
						<Text
							overflow={!showMore ? 'hidden' : 'auto'}
							textOverflow={!showMore ? 'ellipsis' : 'auto'}
							whiteSpace={!showMore ? 'nowrap' : 'normal'}
							w={TEXT_WIDTH}
							fontSize="13px"
						>
							<span ref={overflowingText}>
								<b>Current: </b>
								{currentContent}
							</span>
						</Text>
					</Collapse>
					{overflowActive && (
						<Text
							color="blue.submit"
							cursor="pointer"
							fontSize="xs"
							onClick={() => setShowMore(!showMore)}
						>
							{showMore ? (
								<>
									Less <ChevronUpIcon />
								</>
							) : (
								<>
									More <ChevronDownIcon />
								</>
							)}
						</Text>
					)}
				</Flex>
			)}
		</>
	);
};

export default Header;

export const keyframe_blink = keyframes`
    0% {
      background-color: #f45923ed;
    }
    50% {
		background-color: #9D9D9D;
    }
    100% {
		background-color: #f45923ed;
    }
`;
