import { FC, useContext, useEffect, useRef, useState } from 'react';
import { Box, Flex, Image, useDisclosure } from '@chakra-ui/react';
import { ChatMessageEnum, IMessage, ThreadRolesEnum } from 'src/lib/schemas';
import { AssistantChatContext } from 'src/contexts';
import ThreeDotsAnimation from './animation/ThreeDotsAnimation';
import FusionLoading from '../common/FusionLoading';
import UserLogo from '../common/UserLogo';
import GoogleLogo from '../common/GoogleLogo';
import Suggestion from './Suggestion';
import Message from './Message';
import ZoomedImage from '../common/ZoomedImage';

interface IMessages {
	conversation: Array<IMessage>;
}

const Messages: FC<IMessages> = ({ conversation }) => {
	const LOGO_SIZES = {
		h: 9,
		w: 9,
	};
	const { isChatLoading, isMyAssistantWritting } =
		useContext(AssistantChatContext);
	const lastItemRef = useRef<HTMLDivElement>(null);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedImage, setSelectedImage] = useState<string | null>(null);

	const handleScrollToBottom = () => {
		if (lastItemRef?.current) {
			setTimeout(() => {
				lastItemRef.current?.scrollIntoView({
					behavior: 'instant',
					block: 'start',
				});
			}, 0);
		}
	};

	useEffect(() => {
		handleScrollToBottom();
	}, [conversation, isMyAssistantWritting]);

	const renderAssistantWritting = () => (
		<Flex gap={5} alignItems="center">
			<GoogleLogo size={LOGO_SIZES} />
			<ThreeDotsAnimation />
		</Flex>
	);

	const renderAvatar = (isUser: boolean) =>
		isUser ? <UserLogo size={LOGO_SIZES} /> : <GoogleLogo size={LOGO_SIZES} />;

	const handleImageClick = (src: string) => {
		setSelectedImage(src);
		onOpen();
	};

	const renderSplittedContent = (message: IMessage) => (
		<Flex direction="column" justifyContent="center" gap={2} px={2}>
			{message.splitted_content?.map((content, i) => {
				if (content.type === ChatMessageEnum.Image) {
					return (
						<Box
							key={i}
							borderRadius="md"
							overflow="hidden"
							cursor="pointer"
							onClick={() => handleImageClick(content.value)}
						>
							<Image
								src={content.value}
								alt="User uploaded"
								maxWidth="200px"
								borderRadius="lg"
								objectFit="cover"
							/>
						</Box>
					);
				} else if (content.suggestion) {
					return <Suggestion key={i} content={content.value} />;
				} else {
					return <Message key={i} content={content.value} />;
				}
			})}
		</Flex>
	);

	const renderedMessages = conversation.map((message, i) => {
		const isLastItem = conversation.length - 1 === i;
		const isUser = message.role === ThreadRolesEnum.User;

		return (
			<Flex key={i} my={3} direction={isUser ? 'row-reverse' : 'row'}>
				{renderAvatar(isUser)}
				<Box
					bg={'gray.200'}
					borderRadius={20}
					borderTopLeftRadius={isUser ? 20 : 2}
					borderTopRightRadius={isUser ? 2 : 20}
					p={3}
					mt={3}
					ml={isUser ? 0 : 2}
					mr={isUser ? 2 : 0}
					maxW="80%"
					width="auto"
					overflowWrap="break-word"
					wordBreak="break-word"
					whiteSpace="pre-wrap"
				>
					<Flex direction="column" justifyContent="center" gap={3}>
						{message.raw_message ? (
							<Message
								content={message.raw_message}
								onImageClick={handleImageClick}
								onContentResize={isLastItem ? handleScrollToBottom : undefined}
							/>
						) : (
							renderSplittedContent(message)
						)}
					</Flex>
				</Box>
			</Flex>
		);
	});

	return (
		<>
			<Flex flex={1} direction="column" p={4} overflowX="hidden">
				{isChatLoading ? (
					<FusionLoading isLoading={isChatLoading} />
				) : (
					<>
						{renderedMessages}
						{isMyAssistantWritting && (
							<Flex my={3}>{renderAssistantWritting()}</Flex>
						)}
						<div
							ref={lastItemRef}
							style={{ scrollMarginBottom: '200px' }}
						></div>
					</>
				)}
			</Flex>

			{selectedImage && (
				<ZoomedImage
					isOpen={isOpen}
					onClose={onClose}
					imageSrc={selectedImage}
				/>
			)}
		</>
	);
};

export default Messages;
