import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormEvent, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { StringInputHook } from 'src/components/common/form';
import { validateWebsiteUrl } from 'src/lib/utils';
import ScrapedInfoForm from './ScrapedInfoForm';
import {
	IScrapeDataOutput,
	IScrapeDataResponse,
	IScrappingDataResponse,
	IScrappingFullData,
} from 'src/lib/schemas/scrapping';
import {
	IGetDomainDataSchema,
	GetDomainDataSchema,
} from 'src/lib/schemas/scrap/getDomainData';
import { getFullUrlData, getData, scrapeData } from 'src/services/scraping';
import ThreeDotsAnimation from 'src/components/assistantChat/animation/ThreeDotsAnimation';
import useRoute from 'src/hooks/useRoute';
import { IAppInputLoading } from 'src/contexts/app/AppInputConfigContext';

interface IDomainNotFound {
	prevDomain?: string;
	onDomainSubmit?: (newDomain: string) => void;
	onDataSubmit?: any;
	withManualOption?: boolean;
	onDataLoaded?: (data: IScrapeDataOutput) => void;
	shouldShowForm?: boolean;
	setIsDataLoading?: React.Dispatch<React.SetStateAction<boolean>>;
	isDataLoading?: boolean;
	setIsExtraDataLoading?: (loadingInputs: IAppInputLoading) => void;
	onStartScrapping?: () => void;
	buttonText?: string;
}

const DomainForm = ({
	prevDomain,
	withManualOption,
	onDomainSubmit,
	onDataSubmit,
	onDataLoaded,
	shouldShowForm = false,
	setIsDataLoading,
	isDataLoading,
	setIsExtraDataLoading,
	onStartScrapping,
	buttonText = 'Fill it in',
}: IDomainNotFound) => {
	const [showForm, setShowForm] = useState(false);
	const [domainData, setDomainData] = useState<IScrapeDataOutput>();
	const [domainNotFound, setDomainNotFound] = useState(false);
	// const [isDataLoading, setIsDataLoading] = useState(false);
	const { removeUrlPrefix } = useRoute();
	const formMethods = useForm<IGetDomainDataSchema>({
		resolver: zodResolver(GetDomainDataSchema),
	});
	const { setValue, setError, clearErrors, watch, setFocus, formState } =
		formMethods;
	const url = watch('url');

	const handleSubmit = async () => {
		if (url === '') {
			setError('url', { message: 'Website URL is required' });
			return;
		}

		if (setIsDataLoading) {
			setIsDataLoading(true);
		}
		onStartScrapping && onStartScrapping();
		shouldShowForm && setShowForm(true);
		setDomainData(undefined);
		const validatedUrl = validateWebsiteUrl(url).updatedUrl;
		onDomainSubmit && onDomainSubmit(removeUrlPrefix(validatedUrl));
		try {
			const data = await getFullUrlData(decodeURIComponent(validatedUrl));
			onDataLoaded && onDataLoaded(data);
			setDomainData(data);
			setValue('url', data.url);
			setDomainNotFound(false);
		} catch (error) {
			setDomainNotFound(true);
		} finally {
			if (setIsDataLoading) {
				setIsDataLoading(false);
			}
		}
	};

	const handleWebsiteLinkChange = (e: any, updateValue = false) => {
		const url = e.target.value;
		if (url.trim() === '') {
			clearErrors('url');
			return;
		}
		const { isValid, updatedUrl } = validateWebsiteUrl(url);
		if (!isValid) {
			setError('url', { message: 'Please enter a valid URL' });
			return;
		}
		if (updateValue) {
			setValue('url', updatedUrl);
		}
		clearErrors('url');
	};

	return (
		<FormProvider {...formMethods}>
			<form>
				<Flex
					justifyContent={{ base: 'center', md: 'flex-start' }}
					flexDirection={{ base: 'column', md: 'row' }}
					gap={2}
					alignItems={{ base: 'stretch', md: 'flex-end' }}
				>
					<StringInputHook
						name="url"
						label="Url"
						inputProps={{ w: { base: '100%', md: '300px', lg: '400px' } }}
						placeholder={prevDomain ?? 'http://'}
						formControlProps={{
							onBlur: (e) => handleWebsiteLinkChange(e, true),
							onChange: handleWebsiteLinkChange,
							onKeyDown: (e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
									handleSubmit();
								}
							},
							w: { base: '100%', md: 'auto' },
						}}
						required
						withErrorMessage={false}
					/>
					<Button
						variant="orangeSolid"
						w={{ base: '100%', md: '130px', lg: '150px' }}
						mt={{ base: 2, md: 0 }}
						onClick={handleSubmit}
						isLoading={isDataLoading}
					>
						{buttonText}
					</Button>

					{withManualOption && !showForm && (
						<Button
							variant="blankOutline"
							w={{ base: '100%', md: '130px', lg: '150px' }}
							mt={{ base: 2, md: 0 }}
							onClick={() => {
								setShowForm(true);
								setFocus('url');
							}}
						>
							Enter Manually
						</Button>
					)}
					{isDataLoading && (
						<Flex pb="8px">
							<ThreeDotsAnimation />
						</Flex>
					)}
					{domainNotFound && !isDataLoading && (
						<Text color="red">
							Unable to obtain business information. Please enter it manually or
							try a new website.
						</Text>
					)}
				</Flex>
				<FormControl isInvalid={Boolean(formState.errors['url'])}>
					<FormErrorMessage>
						{formState.errors['url']?.message}
					</FormErrorMessage>
				</FormControl>
				<Box mt={5}>
					{showForm && (
						<ScrapedInfoForm
							isDataLoading={isDataLoading}
							data={domainData}
							onDataSubmit={onDataSubmit}
							websiteLink={url}
							errors={formState.errors}
						/>
					)}
				</Box>
			</form>
		</FormProvider>
	);
};

export default DomainForm;
