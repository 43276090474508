import React, { forwardRef, useState } from 'react';
import {
	Box,
	FormLabel,
	FormControl,
	Input,
	Text,
	Image,
	Flex,
} from '@chakra-ui/react';
import Actions from './Actions';
import {
	Controller,
	ControllerRenderProps,
	FieldValues,
} from 'react-hook-form';
import { useDropzone, FileRejection, Accept } from 'react-dropzone';
import { BiUpload } from 'react-icons/bi';
import { customToast } from 'src/services/toast';

interface CustomInputProps {
	label: string;
	name: string;
	control: any;
	isLayerVisible?: boolean;
	image?: string;
	isLoading?: boolean;
	onChange?: (val: string) => void;
	onFontClick?: () => void;
	onCopilotClick?: () => void;
	onShowClick?: () => void;
	onUnlockClick?: () => void;
	onUploadClick?: (file: File) => void;
	onDownloadClick?: () => void;
}

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
	(
		{
			label,
			name,
			control,
			isLayerVisible,
			image,
			isLoading,
			onChange,
			onFontClick,
			onCopilotClick,
			onShowClick,
			onUnlockClick,
			onUploadClick,
			onDownloadClick,
		},
		ref,
	) => {
		const [isFocused, setIsFocused] = useState(false);
		const isMediaInput = Boolean(image);

		const acceptTypes = (): Accept => ({
			'image/*': ['.png', '.jpg', '.jpeg', '.gif', '.webp', '.svg'],
		});

		const { getRootProps, getInputProps, isDragActive } = useDropzone({
			accept: acceptTypes(),
			multiple: false,
			onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => {
				if (fileRejections.length > 0) {
					fileRejections.forEach((fileRejection) => {
						customToast(
							`File type not accepted: ${fileRejection.file.name}`,
							'error',
						);
					});
				} else if (acceptedFiles.length > 0) {
					const file = acceptedFiles[0];
					onUploadClick && onUploadClick(file);
				}
			},
		});

		const renderInput = (field: ControllerRenderProps<FieldValues, string>) =>
			isMediaInput ? (
				<Box
					{...getRootProps()}
					w="100%"
					h="100%"
					borderRadius="md"
					overflow="hidden"
					position="relative"
					cursor="pointer"
					_hover={{ opacity: 0.8 }}
					transition="opacity 0.2s"
					bg={isDragActive ? 'blue.100' : 'transparent'}
					display="flex"
					alignItems="center"
				>
					<input {...getInputProps()} />
					{image ? (
						<Flex
							justify="flex-start"
							align="center"
							minW="150px"
							maxW="180px"
							p={4}
						>
							<Image
								src={image}
								alt={`${label} image`}
								maxW="100%"
								maxH="100%"
								objectFit="contain"
								h="auto"
								borderRadius="md"
								pointerEvents="none"
							/>
						</Flex>
					) : (
						<Flex justify="center" align="center" w="100%" h="100%">
							<BiUpload size={24} color="#A0AEC0" />
							<Text ml={2} color="#A0AEC0">
								Drag & Drop or Click to Upload
							</Text>
						</Flex>
					)}
					{isDragActive && (
						<Flex
							position="absolute"
							top="0"
							left="0"
							right="0"
							bottom="0"
							bg="rgba(247, 72, 11, 0.3)"
							justify="center"
							align="center"
						>
							<Text color="#F7480B" fontWeight="semibold">
								Drop the image here
							</Text>
						</Flex>
					)}
				</Box>
			) : (
				<Input
					data-testid="string-input"
					{...field}
					ref={ref}
					bg="white"
					variant="unstyled"
					height="50px"
					borderRadius="md"
					px={3}
					fontSize="14px"
					placeholder="Enter text"
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					onChange={(event) => {
						field.onChange(event);
						onChange && onChange(event.target.value);
					}}
				/>
			);

		return (
			<Box
				position="relative"
				border="1px solid"
				borderColor={isFocused ? 'orange.base' : 'border.primary'}
				borderRadius="md"
				w="full"
				bg="white"
				tabIndex={0}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
			>
				<FormControl>
					<FormLabel
						position="absolute"
						top="-12px"
						left="10px"
						bg="white"
						px={1}
						fontWeight="bold"
						zIndex={1}
					>
						<Text color={isFocused ? 'orange.base' : 'text.secondary'}>
							{label}
						</Text>
					</FormLabel>
					<Controller
						control={control}
						name={name}
						render={({ field }) => renderInput(field)}
					/>

					<Actions
						onFontClick={onFontClick}
						onCopilotClick={onCopilotClick}
						onShowClick={onShowClick}
						onUnlockClick={onUnlockClick}
						onUploadClick={onUploadClick}
						onDownloadClick={onDownloadClick}
						isLayerVisible={isLayerVisible}
						isLoading={isLoading}
						layerName={name}
					/>
				</FormControl>
			</Box>
		);
	},
);

CustomInput.displayName = 'CustomInput';
export default CustomInput;
