import { FC, useContext, useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { IDesignDirection } from 'src/lib/schemas';
import DDPhotoEditor from './DDPhotoEditor';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import MediaCreativeModal from '../../MediaCreativeModal';
import UserContext from 'src/contexts/UserContext';
import { getCompanyAccount } from 'src/services/account';

interface DDPreviewProps {
	onLockUnlock: (id: string, locked: boolean, isDD?: boolean) => void;
	textLayersContent?: any;
	designDirection: IDesignDirection;
	onRefetchDesignDirections: () => void;
	previewPSD: string;
	isChangingLayer: boolean;
}
const DDPreview: FC<DDPreviewProps> = ({
	onLockUnlock,
	textLayersContent,
	designDirection,
	onRefetchDesignDirections,
	previewPSD,
	isChangingLayer,
}) => {
	const { user } = useContext(UserContext);
	const [fonts, setFonts] = useState<string[]>([]);
	useEffect(() => {
		getGlobalFont().then(
			(fonts) => fonts && setFonts((prevState) => [...prevState, fonts]),
		);
	}, []);

	const getGlobalFont = async () => {
		if (!user) return;
		const { brandIdentity } = await getCompanyAccount(user.account);
		return brandIdentity?.fontType;
	};

	const designDirectionFormToggle =
		useToggleWithPayload<IDesignDirection | null>();

	const handleEditDesignDirection = () => {
		designDirectionFormToggle.onOpen(designDirection);
	};

	return (
		<>
			<Flex direction="column" gap={4} flex={1}>
				<Flex
					p={4}
					direction="column"
					gap={4}
					borderRadius="md"
					bg="#F8F8F8"
					maxH="630px"
					boxShadow="0px 0.913px 5.478px 0px rgba(0, 0, 0, 0.15)"
				>
					{!designDirectionFormToggle.isOpen && (
						<DDPhotoEditor
							key={previewPSD}
							layeredFile={previewPSD}
							textLayersContent={textLayersContent}
							fonts={fonts}
							designDirection={designDirection}
							onEditDesignDirection={handleEditDesignDirection}
							isChangingLayer={isChangingLayer}
							onRefetchCreatives={onRefetchDesignDirections}
						/>
					)}
					{designDirectionFormToggle.payload && (
						<MediaCreativeModal
							isOpen={designDirectionFormToggle.isOpen}
							data={designDirectionFormToggle.payload}
							onClose={designDirectionFormToggle.onClose}
							onRefetchCreatives={onRefetchDesignDirections}
							layeredFile={previewPSD}
							textLayersContent={textLayersContent}
							fonts={fonts}
						/>
					)}
				</Flex>
			</Flex>
		</>
	);
};

export default DDPreview;
