import { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

const EditIcon2: FC<IconProps> = ({
	width = '20px',
	height = '20px',
	color = 'currentColor',
	...props
}) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			color={color}
			{...props}
		>
			<path
				d="M27.3067 3.60812C25.9334 2.23479 23.72 2.23479 22.36 3.60812L20.8134 5.15479L25.76 10.1015L27.3067 8.55479C28.68 7.18146 28.68 4.96813 27.3067 3.60812Z"
				fill="currentColor"
			/>
			<path
				d="M24.3467 11.5131L19.4001 6.56641L3.20005 22.7664C2.37338 23.5931 1.77338 24.6064 1.44005 25.7131L0.373383 29.2997C0.266717 29.6464 0.373383 30.0331 0.626717 30.2864C0.88005 30.5397 1.26672 30.6464 1.61338 30.5397L5.18672 29.4731C6.30672 29.1397 7.32005 28.5397 8.13338 27.7131L24.3334 11.5131H24.3467Z"
				fill="currentColor"
			/>
			<path
				d="M1.23999 29.9004H23.3467"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
			<defs>
				<clipPath id="clip0_3263_25053">
					<rect
						width="29.4133"
						height="29.76"
						fill="white"
						transform="translate(0.333374 0.833984)"
					/>
				</clipPath>
			</defs>
		</Icon>
	);
};

export default EditIcon2;
