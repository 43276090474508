import http from './http';
import {
	IGoogleIntegrationCustomers,
	IGoogleIntegrationToken,
	IIntegration,
	IIntegrationItem,
} from 'src/lib/schemas';

const apiVersion = 'v1';
const integrationsUrl = `/${apiVersion}/integrations`;

const getAccountIntegrations = async (
	accountId: string,
): Promise<IIntegration> => {
	const { data } = await http.get(`${integrationsUrl}/${accountId}`);
	return data;
};

const saveSelectedPages = async (
	accountId: string,
	item: IIntegrationItem,
): Promise<IIntegration> => {
	const { data } = await http.patch(
		`${integrationsUrl}/${accountId}/facebook`,
		item,
	);
	return data;
};

const getFacebookToken = async (code: string) => {
	const { data } = await http.get('/v2/campaign/facebook/get-token', {
		params: { code },
	});
	return data;
};

const getFacebookPages = async (accessToken: string) => {
	const { data } = await http.get(
		`${integrationsUrl}/facebook/accounts?access_token=${accessToken}`,
	);
	return data;
};

const getInstagramPages = async (accessToken: string) => {
	const { data } = await http.get(
		`https://graph.facebook.com/v20.0/me?fields=businesses{instagram_business_accounts{id,profile_picture_url,username}}&access_token=${accessToken}`,
	);
	return data;
};

const getFacebookAdAccounts = async (accessToken: string) => {
	const { data } = await http.get(
		`https://graph.facebook.com/v20.0/me/adaccounts?fields=name,id,account_id,account_status&access_token=${accessToken}`,
	);
	const filteredAccounts = data.data.filter(
		(account: any) => account.account_status === 1,
	);
	return { data: filteredAccounts };
};

const getFacebookUserPages = async (accessToken: string) => {
	const { data } = await http.get(
		`https://graph.facebook.com/me/?fields=name,picture,id&access_token=${accessToken}`,
	);
	return data;
};

const disconnectFacebook = async (accountId: string) => {
	const { data } = await http.patch(
		`${integrationsUrl}/${accountId}/facebook`,
		{ enabled: false },
	);
	return data;
};

const disconnectGoogleAds = async (accountId: string) => {
	const { data } = await http.patch(`${integrationsUrl}/${accountId}/google`, {
		enabled: false,
	});
	return data;
};

const getGoogleCustomers = async (
	accountId: string,
): Promise<IGoogleIntegrationCustomers[]> => {
	const { data } = await http.get(
		`${integrationsUrl}/${accountId}/google-customers`,
	);
	return data.results;
};

const saveSelectedGoogleAccounts = async (
	accountId: string,
	selectedAccounts: IGoogleIntegrationCustomers[],
	tokens: IGoogleIntegrationToken,
): Promise<Partial<IIntegration['social']>> => {
	const { data } = await http.patch(`${integrationsUrl}/${accountId}/google`, {
		enabled: true,
		attributes: {
			customers: selectedAccounts,
			tokens,
		},
	});
	return data;
};

export {
	getAccountIntegrations,
	saveSelectedPages,
	getFacebookToken,
	getFacebookPages,
	getFacebookUserPages,
	getFacebookAdAccounts,
	disconnectFacebook,
	disconnectGoogleAds,
	getInstagramPages,
	getGoogleCustomers,
	saveSelectedGoogleAccounts,
};
