import React, { useEffect } from 'react';

interface DesktopForcerProps {
	isAuthenticated: boolean;
	setDesktopForced: (value: boolean) => void;
}

const DesktopForcer: React.FC<DesktopForcerProps> = ({
	isAuthenticated,
	setDesktopForced,
}) => {
	useEffect(() => {
		if (isAuthenticated) {
			const meta = document.createElement('meta');
			meta.name = 'viewport';
			meta.content =
				'width=1024, initial-scale=0.3, shrink-to-fit=no, user-scalable=yes';
			const head = document.getElementsByTagName('head')[0];
			head.appendChild(meta);

			setDesktopForced(true);

			return () => {
				if (head.contains(meta)) {
					head.removeChild(meta);
				}
			};
		}
	}, [isAuthenticated, setDesktopForced]);

	return null;
};

export default DesktopForcer;
