import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	Image,
	Spinner,
	Box,
} from '@chakra-ui/react';
import { FC } from 'react';

interface ZoomedImageProps {
	isOpen: boolean;
	onClose: () => void;
	imageSrc: string;
	loaderSrc?: string;
}

const ZoomedImage: FC<ZoomedImageProps> = ({
	isOpen,
	onClose,
	imageSrc,
	loaderSrc,
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size="3xl">
			<ModalOverlay zIndex={2100} bg="rgba(0, 0, 0, 0.8)" />
			<ModalContent
				bg="transparent"
				boxShadow="none"
				p={0}
				containerProps={{
					zIndex: 2100,
				}}
			>
				<ModalCloseButton
					zIndex={2101}
					mt={-2}
					mr={-3}
					color="black"
					size="md"
				/>
				<ModalBody borderRadius="lg" p={8} bg="white">
					<Image
						src={imageSrc}
						alt="Full-size content"
						maxWidth="100%"
						borderRadius="lg"
						objectFit="contain"
						fallback={
							loaderSrc ? (
								<Image
									src={loaderSrc}
									alt="Fallback loader"
									maxWidth="100%"
									borderRadius="lg"
									objectFit="contain"
								/>
							) : (
								<Box
									display="flex"
									alignItems="center"
									justifyContent="center"
									width="100%"
									height="auto"
									position="relative"
								>
									<Spinner size="lg" color="gray.500" />
								</Box>
							)
						}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default ZoomedImage;
