import React, {
	forwardRef,
	useEffect,
	useState,
	useImperativeHandle,
	useRef,
} from 'react';
import { Flex, Button, Text, Icon, Link } from '@chakra-ui/react';
import { IPage } from 'src/lib/schemas';
import PagesList from './PagesList';
import AdAccounts from './AdAccounts';
import { isEmpty } from 'lodash';
import { useMyIntegrations } from 'src/contexts/integration/IntegrationContext';
import { MdWarning } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

interface SocialPagesProps {
	pages: IPage[];
	instagramPages?: IPage[];
	adAccounts?: any[];
	adAccountsLoading: boolean;
	redirectedFromFB: boolean;
	noPagesAvailable: boolean;
}

export interface SocialPagesRef {
	save: () => Promise<void>;
	getSelectedData: () => {
		selectedPages: IPage[];
		selectedAdAccount: any;
		selectedIGPages: IPage[];
		accessTokens: string;
		saveAndPublish: boolean;
	};
}

const SocialPages = forwardRef<SocialPagesRef, SocialPagesProps>(
	(
		{
			pages,
			instagramPages,
			adAccounts,
			adAccountsLoading,
			redirectedFromFB,
			noPagesAvailable,
		},
		ref,
	) => {
		const { state, pathname } = useLocation();
		const [selectedPages, setSelectedPages] = useState<IPage[]>([]);
		const [selectedIGPages, setSelectedIGPages] = useState<IPage[]>([]);
		const [selectedAdAccount, setSelectedAdAccount] = useState<any>();
		const [pagesError, setPagesError] = useState<string>();
		const [instagramPagesError, setInstagramPagesError] = useState<string>();
		const [adAccountError, setAdAccountError] = useState<string>();
		const [isAutoSaving, setIsAutoSaving] = useState<boolean>(false);
		const [isUserAction, setIsUserAction] = useState<boolean>(false);

		const {
			integrations,
			handleSaveSelectedPages,
			isSaving,
			getSelectedPagesIds,
			getSelectedIGAccountsIds,
			shouldLaunch,
			setShouldLaunch,
		} = useMyIntegrations();
		const accessTokens =
			state?.facebookTokens ?? integrations?.social?.fb?.attributes?.tokens;
		const saveAndPublish = pathname.includes('/projects/campaigns/');

		const pagesListRef = useRef<HTMLDivElement>(null);
		const adAccountsRef = useRef<HTMLDivElement>(null);

		const isFirstRender = useRef(true);

		useEffect(() => {
			if (!pages || pages.length === 0) {
				return;
			}
			const selectedIds = getSelectedPagesIds();
			if (selectedIds && selectedIds.length > 0) {
				const tempSelectedPages: IPage[] = [];
				pages.forEach((page) => {
					if (selectedIds.includes(page.id)) {
						tempSelectedPages.push(page);
					}
				});
				setSelectedPages(tempSelectedPages);
			} else {
				setSelectedPages(pages);
			}

			if (instagramPages && instagramPages.length > 0) {
				const selectedIGIds = getSelectedIGAccountsIds();
				if (selectedIGIds && selectedIGIds.length > 0) {
					const tempSelectedIGPages: IPage[] = [];
					instagramPages.forEach((page) => {
						if (selectedIGIds.includes(page.id)) {
							tempSelectedIGPages.push(page);
						}
					});
					setSelectedIGPages(tempSelectedIGPages);
				} else {
					setSelectedIGPages(instagramPages);
				}
			}

			if (integrations?.social?.fb?.attributes?.adAccounts) {
				setSelectedAdAccount(
					integrations?.social?.fb?.attributes?.adAccounts[0],
				);
			} else if (adAccounts && adAccounts.length > 0) {
				setSelectedAdAccount(adAccounts[0]);
			}
		}, [
			pages,
			integrations,
			adAccounts,
			instagramPages,
			getSelectedPagesIds,
			getSelectedIGAccountsIds,
		]);

		useEffect(() => {
			if (isFirstRender.current) {
				isFirstRender.current = false;
				return;
			}
			if (isUserAction) {
				autoSave();
			}
		}, [selectedPages, selectedIGPages, selectedAdAccount]);

		const handleSelectPage = (selectedPage: IPage) => {
			if (
				selectedPages.length === 1 &&
				selectedPages[0].id === selectedPage.id
			) {
				return;
			}

			setIsUserAction(true);

			setSelectedPages((prevSelectedPages) => {
				const isAlreadySelected = prevSelectedPages.some(
					(page) => page.id === selectedPage.id,
				);
				const newSelectedPages = isAlreadySelected
					? prevSelectedPages.filter((page) => page.id !== selectedPage.id)
					: [...prevSelectedPages, selectedPage];
				return newSelectedPages;
			});
			setPagesError(undefined);
		};

		const handleSelectIGPage = (selectedPage: IPage) => {
			if (
				selectedIGPages.length === 1 &&
				selectedIGPages[0].id === selectedPage.id
			) {
				return;
			}

			setIsUserAction(true);

			setSelectedIGPages((prevSelectedPages) => {
				const isAlreadySelected = prevSelectedPages.some(
					(page) => page.id === selectedPage.id,
				);
				const newSelectedIGPages = isAlreadySelected
					? prevSelectedPages.filter((page) => page.id !== selectedPage.id)
					: [...prevSelectedPages, selectedPage];
				return newSelectedIGPages;
			});
			setInstagramPagesError(undefined);
		};

		const handleSelectAdAccount = (account: any) => {
			setIsUserAction(true);
			setSelectedAdAccount(account);
			setAdAccountError(undefined);
		};

		const handleSubmit = async (saveAndPublish: boolean) => {
			let hasError = false;

			if (isEmpty(selectedPages)) {
				setPagesError('Please select at least one page');
				pagesListRef.current?.scrollIntoView({ behavior: 'smooth' });
				hasError = true;
			} else {
				setPagesError(undefined);
			}

			// Uncomment if you want to require selecting an ad account
			/*
		if (!selectedAdAccount || selectedAdAccount.id === undefined) {
		  setAdAccountError('Please select an ad account option');
		  adAccountsRef.current?.scrollIntoView({ behavior: 'smooth' });
		  hasError = true;
		} else {
		  setAdAccountError(undefined);
		}
		*/

			if (hasError) {
				return;
			}

			const dataToSave = {
				accessTokens,
				selectedPages,
				selectedAdAccount,
				saveAndPublish,
				selectedIGPages,
			};

			if (instagramPages != null) {
				await handleSaveSelectedPages(
					accessTokens,
					selectedPages,
					selectedAdAccount,
					saveAndPublish,
					selectedIGPages,
				);
			} else {
				await handleSaveSelectedPages(
					accessTokens,
					selectedPages,
					selectedAdAccount,
					saveAndPublish,
				);
			}

			if (saveAndPublish && shouldLaunch) {
				setShouldLaunch(false);
			}
		};

		const autoSave = async () => {
			if (isSaving || isAutoSaving) {
				return;
			}

			setIsAutoSaving(true);
			try {
				await handleSubmit(false);
			} catch (error) {
			} finally {
				setIsAutoSaving(false);
				setIsUserAction(false);
			}
		};

		useImperativeHandle(ref, () => ({
			save: () => handleSubmit(true),
			getSelectedData: () => ({
				selectedPages,
				selectedAdAccount,
				selectedIGPages,
				accessTokens,
				saveAndPublish,
			}),
		}));

		if (noPagesAvailable) {
			return (
				<Flex
					direction="row"
					align="center"
					p={4}
					bg="#FEF3C7"
					borderRadius="lg"
				>
					<Icon as={MdWarning} color="#D97706" mr={2} />
					<Text flex="1" fontSize="sm" color="#111827" fontWeight="500">
						You need at least one page associated, please create a Facebook page
						and reconnect your account by clicking on &quot;Change account&quot;
					</Text>
					<Link href="https://www.facebook.com/pages/create" isExternal>
						<Button colorScheme="blue" size="sm">
							Create Facebook Page
						</Button>
					</Link>
				</Flex>
			);
		}

		if (pages.length >= 1) {
			return (
				<Flex direction="column" w="full" mb={5} gap={5}>
					<div ref={pagesListRef}>
						<PagesList
							title="In which pages do you want to show your posts?"
							facebookIcon={true}
							pages={pages}
							selectedPages={selectedPages}
							onSelect={handleSelectPage}
							error={pagesError}
						/>
					</div>
					{instagramPages && instagramPages.length > 0 && (
						<div ref={pagesListRef}>
							<PagesList
								title="In which accounts do you want to show your posts?"
								pages={pages}
								instagramPages={instagramPages}
								selectedPages={selectedIGPages}
								onSelect={handleSelectIGPage}
								error={instagramPagesError}
							/>
						</div>
					)}
					<div ref={adAccountsRef}>
						<AdAccounts
							title="If you want paid ads, please select the ad account"
							accounts={adAccounts}
							selectedAccount={selectedAdAccount}
							onSelect={handleSelectAdAccount}
							isLoading={adAccountsLoading}
							error={adAccountError}
						/>
					</div>
					{/* Optional manual save button */}
					{/* <Button
			  w="fit-content"
			  minW={15}
			  alignSelf="flex-end"
			  colorScheme="secondary"
			  type="button"
			  isLoading={isSaving}
			  loadingText="Saving..."
			  onClick={() => handleSubmit(saveAndPublish)}
			>
			  {saveAndPublish ? 'Save and publish' : 'Save'}
			</Button> */}
				</Flex>
			);
		}

		return null;
	},
);

SocialPages.displayName = 'SocialPages';

export default SocialPages;
