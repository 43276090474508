import http from './http';

const apiVersion = 'v1';
const accountUrl = `/${apiVersion}/account`;

export interface IIndustry {
	industryIds: string[];
	industryId?: string;
	name: string;
	parentId?: string | null;
	children?: IIndustry[] | null;
	categoryId?: string;
	entityType?: string;
}

const getIndustries = async (): Promise<IIndustry[]> => {
	const { data } = await http.get<IIndustry[]>(`${accountUrl}/industries/tree`);
	return data;
};

export { getIndustries };
