import { z } from 'zod';

interface IIndustry {
	categoryId?: string;
	entityType?: string;
	id?: string;
	industryId?: string;
	industryIds: string[];
	name: string;
	parentId?: string | null;
}

interface IBrand {
	description: string;
	industry: IIndustry;
	keywords: string[];
	logo: string;
	name: string;
	url: string;
}

export interface IGetDomainDataSchema {
	catalogsExecution?: string | null;
	catalogsFound: number;
	deduceTonesAudiencesExecution?: string | null;
	description: string;
	keywords: string[];
	pageType?: string;
	title?: string;
	url: string;
	brand: IBrand;
}

export const IndustrySchema = z.object({
	categoryId: z.string(),
	entityType: z.string().optional(),
	id: z.string().optional(),
	industryId: z.string().optional(),
	industryIds: z.array(z.string()),
	name: z.string(),
	parentId: z.string().nullable().optional(),
});

const BrandSchema = z.object({
	description: z.string(),
	industry: IndustrySchema,
	keywords: z.array(z.string()),
	logo: z.string(),
	name: z.string(),
	url: z.string(),
});

export const GetDomainDataSchema: z.ZodType<IGetDomainDataSchema> = z.object({
	catalogsExecution: z.string().nullable().optional(),
	catalogsFound: z.number(),
	deduceTonesAudiencesExecution: z.string().nullable().optional(),
	description: z.string(),
	keywords: z.array(z.string()),
	pageType: z.string().optional(),
	title: z.string().optional(),
	url: z.string(),
	brand: BrandSchema,
});
