import {
	Flex,
	Input,
	Button,
	IconButton,
	Image,
	Box,
	Wrap,
	WrapItem,
	Text,
} from '@chakra-ui/react';
import { FormEvent, useContext, useRef, useState } from 'react';
import { HiOutlinePaperClip, HiPaperAirplane } from 'react-icons/hi2';
import { AssistantChatContext } from 'src/contexts';
import {
	ChatMessageEnum,
	IMessage,
	IThreadPayload,
	ThreadRolesEnum,
} from 'src/lib/schemas';
import { uploadFile } from 'src/services/fileUpload';

const Actions = () => {
	const [content, setContent] = useState('');
	const [selectedImages, setSelectedImages] = useState<File[]>([]);
	const [uploadErrors, setUploadErrors] = useState<Record<string, string>>({});
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [isSendingMessage, setIsSendingMessage] = useState(false);
	const {
		thread,
		isMyAssistantWritting,
		shouldSubmitBeAble,
		isChatLoading,
		addMessage,
		sendMessage,
	} = useContext(AssistantChatContext);

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();
		if ((!content && !selectedImages.length) || isMyAssistantWritting) return;
		setIsSendingMessage(true);
		setUploadErrors({});

		let imagesMarkdownText = '';
		const newUploadErrors: Record<string, string> = {};

		if (selectedImages.length) {
			const imageUploadPromises = selectedImages.map(async (image) => {
				try {
					const { imageUrl } = await uploadFile(image);
					return `![](${imageUrl})`;
				} catch (error: any) {
					newUploadErrors[image.name] =
						error?.response?.data?.message ||
						'Upload failed. Please try again.';
					return null;
				}
			});

			const uploadedMarkdown = await Promise.all(imageUploadPromises);
			imagesMarkdownText = uploadedMarkdown.filter(Boolean).join(' ');

			if (Object.keys(newUploadErrors).length > 0) {
				setUploadErrors(newUploadErrors);
				setIsSendingMessage(false);
				return;
			}
		}

		const updatedContent =
			content + (imagesMarkdownText ? ' ' + imagesMarkdownText : '');

		const payload: IThreadPayload = {
			user_message: updatedContent,
			thread_id: thread?.threadId,
		};

		const newMessage: IMessage = {
			role: ThreadRolesEnum.User,
			splitted_content: [
				...(content
					? [
							{
								value: content,
								type: ChatMessageEnum.String,
								suggestion: false,
							},
					  ]
					: []),
				...selectedImages.map((file) => ({
					value: URL.createObjectURL(file),
					type: ChatMessageEnum.Image,
					suggestion: false,
				})),
			],
		};

		addMessage(newMessage);
		sendMessage(payload);
		setContent('');
		setSelectedImages([]);
		setIsSendingMessage(false);
	};

	const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			const files = Array.from(event.target.files);

			setSelectedImages((prevImages) => {
				const existingFiles = new Set(
					prevImages.map((file) => file.name + file.size),
				);
				const uniqueFiles = files.filter(
					(file) => !existingFiles.has(file.name + file.size),
				);

				return [...prevImages, ...uniqueFiles];
			});
		}

		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
	};

	const removeImage = (index: number) => {
		setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
		setUploadErrors((prevErrors) => {
			const newErrors = { ...prevErrors };
			delete newErrors[selectedImages[index]?.name];
			return newErrors;
		});
	};

	return (
		<Box
			bg="gray.100"
			py="24px"
			px="16px"
			pt={selectedImages.length ? '14px' : '24px'}
		>
			{selectedImages.length > 0 && (
				<Wrap mb={2}>
					{selectedImages.map((image, index) => (
						<WrapItem key={index} position="relative">
							<Box>
								<Image
									src={URL.createObjectURL(image)}
									alt={`Selected Image ${index + 1}`}
									boxSize="50px"
									objectFit="cover"
									borderRadius={5}
								/>
								<Button
									size="xs"
									colorScheme="gray"
									position="absolute"
									top={-2}
									right={-2}
									borderRadius="full"
									minH="fit-content"
									p={0}
									onClick={() => removeImage(index)}
									fontSize={15}
								>
									&times;
								</Button>
								{uploadErrors[image.name] && (
									<Text color="red.500" fontSize="xs" mt={1}>
										{uploadErrors[image.name]}
									</Text>
								)}
							</Box>
						</WrapItem>
					))}
				</Wrap>
			)}
			<form onSubmit={handleSubmit} style={{ width: '100%' }}>
				<Box
					borderWidth="1px"
					borderRadius="md"
					display="flex"
					alignItems="center"
					bg="white"
					pl={3}
				>
					<Input
						placeholder="Tell us..."
						variant="unstyled"
						flex="1"
						_focus={{ outline: 'none' }}
						autoFocus
						h="40px"
						minW="70%"
						value={content}
						onChange={(e) => setContent(e.target.value)}
					/>
					<Flex gap={0}>
						<IconButton
							aria-label="Attach"
							icon={
								<HiOutlinePaperClip style={{ width: '20px', height: '20px' }} />
							}
							variant="blank"
							onClick={() =>
								document.getElementById('image-upload-multiple')?.click()
							}
						/>
						<Input
							id="image-upload-multiple"
							type="file"
							accept="image/*"
							multiple
							display="none"
							onChange={handleImageUpload}
							ref={fileInputRef}
						/>

						<IconButton
							aria-label="Send"
							icon={
								<HiPaperAirplane style={{ width: '20px', height: '20px' }} />
							}
							variant="blank"
							color="orange.base"
							isDisabled={isChatLoading || !shouldSubmitBeAble}
							isLoading={isSendingMessage}
							type="submit"
						/>
					</Flex>
				</Box>
			</form>
		</Box>
	);
};

export default Actions;
