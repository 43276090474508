import { FC } from 'react';
import {
	Box,
	Flex,
	Text,
	Radio,
	Image,
	RadioGroup,
	List,
	ListItem,
	FormControl,
	FormErrorMessage,
} from '@chakra-ui/react';
import { IGoogleIntegrationCustomers } from 'src/lib/schemas';

interface GoogleAccountsListProps {
	title: string;
	customers: IGoogleIntegrationCustomers[];
	selectedCustomer: IGoogleIntegrationCustomers | null;
	onSelect: (customer: IGoogleIntegrationCustomers) => void;
	error?: string;
}

const GoogleAccountsList: FC<GoogleAccountsListProps> = ({
	customers,
	selectedCustomer,
	onSelect,
	error,
}) => {
	return (
		<Box>
			<FormControl isInvalid={Boolean(error)} mb={4}>
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>

			<Flex align="center" mb={4}>
				<Image
					src="/images/home/google.png"
					alt="Google Icon"
					width="24px"
					height="auto"
					mr={3}
				/>
				<Text ml={2}>Which Google Ad Account do you want to use?</Text>
			</Flex>

			<RadioGroup
				value={selectedCustomer?.id}
				onChange={(value) => {
					const customer = customers.find((c) => c.id === value);
					if (customer) {
						onSelect(customer);
					}
				}}
			>
				<List spacing={3}>
					{customers.map((customer) => (
						<ListItem
							key={customer.id}
							p={3}
							boxShadow="md"
							borderRadius="md"
							bg="gray.50"
							cursor="pointer"
							onClick={() => onSelect(customer)}
						>
							<Flex align="center">
								<Radio
									value={customer.id}
									mr={3}
									sx={{
										'&[data-checked]': {
											bg: '#F7480B',
											borderColor: '#F7480B',
										},
										'&[data-checked]:hover': {
											bg: '#F7480B',
											borderColor: '#F7480B',
										},
										'&[aria-checked="true"]:hover': {
											bg: '#F7480B',
											borderColor: '#F7480B',
										},
										'&:hover': {
											borderColor: '#D63A0A',
										},
									}}
								/>

								<Flex direction="column">
									<Text fontWeight="medium">{customer.descriptiveName}</Text>
									<Text fontSize="sm" color="gray.500">
										{customer.id}
									</Text>
								</Flex>
							</Flex>
						</ListItem>
					))}
				</List>
			</RadioGroup>
		</Box>
	);
};

export default GoogleAccountsList;
