import { IIndustry } from 'src/services/industries';
import { z } from 'zod';
import { IndustrySchema } from '../scrap/getDomainData';

export interface IAccountSettingsForm {
	websiteLink?: string;
	name: string;
	description: string;
	keywords: string[];
	industry: IIndustry;
	logo?: string;
	brandIdentity?: { fontType?: string };
	account: string;
}

const AccountSettingsSchema = z.object({
	websiteLink: z.string().url('Please enter a valid URL').optional(),
	name: z.string().min(1, 'Business name is required'),
	description: z.string().min(1, 'Description is required'),
	industry: IndustrySchema.partial(),
	keywords: z.array(z.string()).min(1, 'Keywords are required'),
	logo: z.string().url('Please enter a valid URL').optional(),
	brandIdentity: z
		.object({
			fontType: z.string().optional(),
		})
		.optional(),
	account: z.string(),
});

export const PartialAccountSettingsSchema = AccountSettingsSchema.partial();

export default AccountSettingsSchema;
