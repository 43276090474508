import { ICampaign } from 'src/lib/schemas';
import { ICreative } from 'src/lib/schemas/campaign/newFlowCampaign';
import { SUPPORTED_CHANNELS } from './constants';

export const countPendingCreativesByChannel = (
	campaign: ICampaign | null,
	creatives: ICreative[],
): number => {
	return (
		campaign?.channels?.reduce((acc, channel) => {
			if (
				SUPPORTED_CHANNELS.includes(channel) &&
				creatives?.some(
					(creative) =>
						SUPPORTED_CHANNELS.includes(creative.channel) &&
						creative.channel === channel &&
						(creative.status === 'pending' || creative.status === 'generating'),
				)
			) {
				return acc + 1;
			}
			return acc;
		}, 0) || 0
	);
};
