import React, { FC, useEffect, useContext, useState } from 'react';
import {
	Flex,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	ModalCloseButton,
} from '@chakra-ui/react';
import { CampaignContext } from 'src/contexts';
import { useLocation } from 'react-router-dom';
import { Integrations } from 'src/pages';

interface ICampaignSuccessModalProps {
	isOpen: boolean;
	onClose: () => void;
	redirectedFromFB: boolean;
	redirectedFromGoogle?: boolean;
}

const IntegrationsModal: FC<ICampaignSuccessModalProps> = ({
	isOpen,
	onClose,
	redirectedFromFB,
}) => {
	const location = useLocation();
	const { campaign } = useContext(CampaignContext);
	const searchParams = new URLSearchParams(location.search);
	const redirectedFromGoogle = searchParams.has('fromGoogle');

	const [hasBeenClosed, setHasBeenClosed] = useState(true);

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'unset';
		}

		return () => {
			document.body.style.overflow = 'unset';
		};
	}, [isOpen]);

	const handleClose = () => {
		setHasBeenClosed(true);
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={true}>
			<ModalOverlay zIndex={1800} />
			<ModalContent my="auto" maxW="600px" containerProps={{ zIndex: 1900 }}>
				<ModalCloseButton zIndex="2000" />
				<ModalBody
					bgColor="transparent"
					display="flex"
					flexDirection="column"
					alignItems="center"
					pt={0}
					pb={0}
					css={{
						'&::-webkit-scrollbar': {
							width: '8px',
							backgroundColor: '#F5F5F5',
							visibility: 'visible',
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: '#888',
							borderRadius: '4px',
						},
						'&::-webkit-scrollbar-thumb:hover': {
							backgroundColor: '#555',
						},
						overflowY: 'auto',
						maxHeight: '90vh',
					}}
				>
					<Flex direction="column" gap={8} w="full" alignItems="center">
						<Integrations
							redirectedFromFB={redirectedFromFB}
							redirectedFromGoogle={redirectedFromGoogle}
							isCampaignContext={true}
							selectedChannels={campaign?.channels}
							hasBeenClosed={hasBeenClosed}
						/>
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default IntegrationsModal;
