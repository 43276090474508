import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';

import Overlay from './Overlay';
import FusionLoading from './FusionLoading';
import _, { isArray } from 'lodash';
import ContentPendingProgress from './ContentPendingProgress';

interface PhotoEditorPreviewProps {
	input: {
		files: string[];
		resources?: string[];
	};
	isEditorLoading: boolean;
	onLoadingFinished: () => void;
	onDocumentCount: (count: number) => void;
	showOverlay?: boolean;
	headlineText?: any;
	fonts?: string[];
	onDownload: (file: ArrayBuffer) => void;
	onSave: (file: ArrayBuffer) => void;
	isSubmitting: boolean;
	showPendingProgress: boolean;
	onPendingProgressFinished: (val: boolean) => void;
	// handleClearErrors: () => void;
}

const PhotoEditorPreview: FC<PhotoEditorPreviewProps> = ({
	input,
	isEditorLoading,
	onLoadingFinished,
	onDocumentCount,
	showOverlay,
	headlineText,
	fonts,
	onDownload,
	onSave,
	isSubmitting,
	showPendingProgress,
	onPendingProgressFinished,
	// handleClearErrors,
}) => {
	const resources = fonts?.length?fonts:[]

	const DEFAULT_EDITOR_SETTINGS = {
		resources,
		environment: {
			vmode: 2,
			theme: 2,
			topt: { t0: [0, 0, 0], t16: [0, 0, 0] },
		},
	};

	const [eventCounts, setEventCounts] = useState(0);
	const [eventName, setEventName] = useState('');
	const ppRef = useRef<any>(null);
	const eventNameRef = useRef(eventName);

	const combinedInput = {
		...DEFAULT_EDITOR_SETTINGS,
		...input,
	};

	const parsedInput = encodeURIComponent(JSON.stringify(combinedInput));
	const photopeaUrl = `https://www.photopea.com#${parsedInput}`;

	useEffect(() => {
		if (isEditorLoading && eventCounts >= 1) onLoadingFinished();
	}, [eventCounts, isEditorLoading]);

	useEffect(() => {
		window.addEventListener('message', handleEvents);
		return () => window.removeEventListener('message', handleEvents);
	}, []);

	useEffect(() => {
		eventNameRef.current = eventName;
	}, [eventName]);

	const handleEvents = (e: MessageEvent) => {
		if (e.data === 'done') setEventCounts((prev) => prev + 1);

		const isSaveAction = e.data instanceof ArrayBuffer;
		if (isSaveAction) {
			eventNameRef.current === 'save' && onSave(e.data);
			eventNameRef.current === 'download' && onDownload(e.data);
			setEventName('');
		} else if (
			typeof e?.data === 'string' &&
			(e.data as string)?.indexOf('documentCount') === 0
		) {
			const documentCount = Number(e.data.split('=').pop());
			onDocumentCount(documentCount);
		}
	};

	const handleLoadFile = async (event: ChangeEvent<HTMLInputElement>) => {
		const pp = ppRef.current?.contentWindow;
		if (!pp) return;

		pp.postMessage(
			'app.activeDocument.close(SaveOptions.DONOTSAVECHANGES)',
			'*',
		);

		const file = event.target.files?.[0];
		if (!file) return;

		const buffer = await file.arrayBuffer();
		pp.postMessage(buffer, '*');
		handleTriggerSave('save');
	};

	useEffect(() => {
		const intervalId = setInterval(handleCountDocuments, 500);

		return () => clearInterval(intervalId);
	}, [isEditorLoading]);

	const handleCountDocuments = () => {
		const pp = ppRef.current?.contentWindow;
		if (!pp) return;

		pp.postMessage(
			'app.echoToOE("documentCount="+(app.documents.length));',
			'*',
		);
	};

	const handleChangeHeadline = async () => {
		const pp = ppRef.current?.contentWindow;
		if (!pp || !headlineText) return;
		if (isArray(headlineText)) {
			headlineText.forEach((layer) => {
				let groups = '';
				let previousGroup = 'app.activeDocument';
				layer.parents.forEach((parent: string, index: number) => {
					groups += `
					var group${index} = ${previousGroup}.layerSets.getByName('${parent}');
			`;
					previousGroup = `group${index}`;
				});
				pp.postMessage(
					`${groups}
					var textLayer = ${previousGroup}.layers.getByName('${layer.name}');
					textLayer.textItem.contents = '${layer.content
						.replace(/'/g, "\\'")
						.replace(/\n/g, '\\r')}';
					`,
					'*',
				);
			});
		}
	};

	const handleTriggerSave = (action: string) => {
		setEventName(action);
		const pp = ppRef.current?.contentWindow;
		if (!pp) return;

		pp.postMessage('app.activeDocument.saveToOE("psd");', '*');
	};

	return (
		<Box maxW="555px" minH="full" pos="relative" overflow="hidden">
			<input
				type="file"
				accept=".psd"
				onChange={handleLoadFile}
				style={{ display: 'none' }}
				id="file-input"
			/>
			{showOverlay && (
				<Overlay zIndex={50} h="full" bg="rgba(255, 255, 255, 0.5)">
					<Flex w="full" h="full" justify="center" align="center">
						<FusionLoading isLoading={showOverlay} />
					</Flex>
				</Overlay>
			)}
			<Overlay zIndex={50} h="full" bg="rgba(255, 255, 255, 0)">
				<Flex w="full" h="full" justify="center" align="center"></Flex>
			</Overlay>

			<Box width="690px" height="610px" overflow="hidden">
				{showPendingProgress && (
					<Overlay zIndex={50} w="full" h="92%" bg="rgba(105, 105, 105, 0.5)">
						<Flex w="full" h="full" justify="center" align="center">
							<Flex w="75%" h="full" justify="center" align="center">
								<ContentPendingProgress
									time={30}
									isPendingGeneration={isSubmitting}
									onGenerateStatusChange={(val) => {
										onPendingProgressFinished(val);
									}}
									progressText="Uploading..."
									isGray
								/>
							</Flex>
						</Flex>
					</Overlay>
				)}
				<iframe
					src={photopeaUrl}
					width="100%"
					height="100%"
					ref={ppRef}
					style={{
						marginTop: '-60px',
						marginLeft: '-70px',
					}}
				/>
			</Box>
			<Button
				onClick={() => handleChangeHeadline()}
				display="none"
				id="change-text-layer-button-preview"
			/>
			<Button
				onClick={() => handleTriggerSave('download')}
				display="none"
				id="psd-preview-download-button"
			>
				download
			</Button>
		</Box>
	);
};

export default PhotoEditorPreview;
