import { FC } from 'react';
import { Image } from '@chakra-ui/react';
import Logo from 'src/assets/svgs/google-logo.svg';

interface GoogleLogoProps {
	size: {
		w: number;
		h: number;
	};
}

const GoogleLogo: FC<GoogleLogoProps> = ({ size }) => (
	<Image src={Logo} alignSelf="baseline" {...size} />
);

export default GoogleLogo;
