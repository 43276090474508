import { FC, ReactNode, forwardRef, useEffect } from 'react';
import {
	Flex,
	Text,
	FormControl,
	FormControlProps,
	FormLabel,
	FormLabelProps,
	FormErrorMessage,
	Skeleton,
} from '@chakra-ui/react';
import {
	ArrowForwardIcon,
	ChevronDownIcon,
	InfoIcon,
	SearchIcon,
} from '@chakra-ui/icons';
import Select, {
	ControlProps,
	DropdownIndicatorProps,
	GroupBase,
	OptionProps,
	StylesConfig,
	components,
} from 'react-select';

import { IOption } from 'src/lib/schemas/misc';

export interface SelectSearchInputProps {
	name: string;
	label?: string;
	requirementsLabel?: string;
	id?: string;
	placeholder?: string;
	error?: ReactNode;
	required?: boolean;
	options: Array<IOption>;
	value?: string | IOption;
	onChange?: (val: string | IOption) => void;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	isClearable?: boolean;
	valueAsObject?: boolean;
	onValueChangeCallback?: (val: string | IOption) => void;
	isLoading?: boolean;
	subLabel?: string;
	defaultValue?: string;
}

const Control: FC<ControlProps<IOption, false, GroupBase<IOption>>> = ({
	children,
	...props
}) => (
	<components.Control {...props}>
		<SearchIcon mx={2} color="#B8C2D0" /> {children}
	</components.Control>
);

const Option: FC<OptionProps<IOption, false, GroupBase<IOption>>> = ({
	children,
	...props
}) => (
	<components.Option {...props}>
		<Flex justify="space-between">
			{children}
			<ArrowForwardIcon boxSize={4} alignSelf="right" />
		</Flex>
	</components.Option>
);

const DropdownIndicator: FC<
	DropdownIndicatorProps<IOption, false, GroupBase<IOption>>
> = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<ChevronDownIcon w={5} h={5} />
		</components.DropdownIndicator>
	);
};

const SelectSearchInput = (
	{
		label,
		placeholder,
		requirementsLabel,
		options,
		value,
		onChange,
		required,
		error,
		formControlProps,
		formLabelProps,
		isClearable,
		valueAsObject,
		isLoading,
		subLabel,
		defaultValue,
	}: SelectSearchInputProps,
	ref: any,
) => {
	const hasError = !!error;

	const renderLabelIndicator = () => {
		if (!required) return null;
		if (error) return <InfoIcon color="#e53e3e" />;
		return <Text color="#e53e3e">*</Text>;
	};
	const selectedValue = valueAsObject
		? (value as IOption)
		: options.find((o) => o.value === value);

	return (
		<FormControl isInvalid={hasError} {...formControlProps} w="full">
			<Flex direction="column">
				{label && (
					<Flex justify="space-between" alignItems="center">
						<FormLabel {...formLabelProps}>
							<Flex gap={1} alignItems="center">
								{label}
								{subLabel && (
									<Text ml={1} color="#718096" fontSize="smaller">
										{subLabel}
									</Text>
								)}
								{renderLabelIndicator()}
							</Flex>
							{requirementsLabel && (
								<Flex>
									<Text fontSize="12px" color="#959595">
										{requirementsLabel}
									</Text>
								</Flex>
							)}
						</FormLabel>
					</Flex>
				)}
			</Flex>
			{isLoading ? (
				<Skeleton h="40px" minWidth="400px" borderRadius="5px" />
			) : (
				<Select
					menuPortalTarget={document.body}
					menuPosition="fixed"
					menuPlacement="auto"
					placeholder={placeholder}
					options={options}
					value={selectedValue}
					onChange={(val) => {
						if (!onChange) return;
						const valueSelected = val as IOption;
						if (valueAsObject) {
							onChange(valueSelected);
							return;
						} else {
							if (val === null) {
								onChange('');
								return;
							}
							onChange(valueSelected?.value);
						}
					}}
					components={{
						DropdownIndicator,
						Control,
						Option,
					}}
					styles={{
						...styles,
						...(hasError ? stylesWithErros : { control: styles.control }),
					}}
					ref={ref}
					isClearable={isClearable}
					isLoading={isLoading}
					noOptionsMessage={() => `No element found`}
					isSearchable={true}
				/>
			)}
			{error && <FormErrorMessage>{error}</FormErrorMessage>}
		</FormControl>
	);
};

const styles: StylesConfig<IOption, false, GroupBase<IOption>> = {
	control: (baseStyles, state) => ({
		...baseStyles,
		padding: 0,
		minHeight: '40px',
		height: '40px',
		borderColor: 'inherit',
		borderRadius: '5px',
		boxShadow: state.isFocused ? '0 0 0 1px #2C6ECB' : 'none',
		border: state.isFocused ? '1px solid #2C6ECB' : '1px solid #E2E8F0',
		cursor: 'text',
		':hover': {
			...baseStyles[':hover'],
			borderColor: state.isFocused ? '#2C6ECB' : '#E2E8F0',
			cursor: 'text',
		},
	}),
	valueContainer: (baseStyles) => ({
		...baseStyles,
		minHeight: '40px',
		height: '40px',
		padding: 0,
		paddingRight: '10px',
		fontSize: '14px',
		lineHeight: '20px',
		color: '#202223',
	}),
	input: (baseStyles) => ({
		...baseStyles,
		margin: 0,
		fontSize: '14px',
		lineHeight: '20px',
		color: '#202223',
		padding: 0,
	}),
	menu: (provided) => ({
		...provided,
		maxHeight: '300px',
		overflow: 'scroll',
		zIndex: 9999,
	}),
	menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
	placeholder: (baseStyles) => ({
		...baseStyles,
		fontSize: '14px',
		lineHeight: '20px',
		color: '#A0AEC0',
	}),
	indicatorsContainer: (baseStyles) => ({
		...baseStyles,
		padding: '0 10px',
	}),
	dropdownIndicator: () => ({
		color: '#2D3748',
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	option: (baseStyles, { isSelected, isFocused }) => {
		let backgroundColor = 'inherit';
		if (isFocused) backgroundColor = '#f9f9fa';
		if (isSelected) backgroundColor = '#E2E8F0';

		return {
			...baseStyles,
			fontSize: '14px',
			lineHeight: '20px',
			cursor: 'pointer',
			color: '#202223',
			padding: '8px 16px 8px 16px',
			borderBottom: '1px solid #E2E8F0',
			backgroundColor,
		};
	},
};

const stylesWithErros: StylesConfig<IOption, false, GroupBase<IOption>> = {
	control: (baseStyles) => ({
		...baseStyles,
		padding: 0,
		minHeight: '40px',
		height: '40px',
		borderRadius: '5px',
		boxShadow: '0 0 0 1px #E53E3E',
		borderColor: '#E53E3E',
		':hover': {
			boxShadow: '0 0 0 1px #E53E3E',
			borderColor: '#E53E3E',
			cursor: 'text',
		},
	}),
};

export default forwardRef(SelectSearchInput);
