// src/components/campaign/DesignOutput/parts/DesignDirections/parts/EditDDModal.tsx

import React, { useState, useContext } from 'react';
import { generateCampaignCreatives, getCampaign } from 'src/services/campaign';
import { toastError, toastSuccess } from 'src/services/toast';
import { CampaignContext } from 'src/contexts';

import {
	Button,
	Text,
	Spacer,
	HStack,
	Checkbox,
	Box,
	VStack,
} from '@chakra-ui/react';
import EditDDForm from './EditDDForm';
import { IDesignDirection } from 'src/lib/schemas';
import { delay } from 'src/lib/utils';
import CustomModal from 'src/components/common/CustomModal';

interface EditDDFormModalProps {
	isOpen: boolean;
	onClose: () => void;
	handleRefreshDesignDirections: () => void;
	initialValues: IDesignDirection;
	designDirections: IDesignDirection[];
	setLoadingCreatives: (val: boolean) => void;
}

const EditDDModal: React.FC<EditDDFormModalProps> = ({
	isOpen,
	onClose,
	handleRefreshDesignDirections,
	initialValues,
	designDirections,
	setLoadingCreatives,
}) => {
	const [isSaving, setIsSaving] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [designerMode, setDesignerMode] = useState(false);
	const [regenerateAll, setRegenerateAll] = useState(true);
	const isLoading = isSaving || isUploading;
	const { id: campaignId, setCampaign } = useContext(CampaignContext);

	const handleSave = async () => {
		onClose();
		await delay(1000);
		handleRefreshDesignDirections();

		if (regenerateAll) {
			if (!campaignId || !initialValues.id) return;
			try {
				setLoadingCreatives(true);
				await generateCampaignCreatives(campaignId, initialValues.id);
				const data = await getCampaign(campaignId);
				setCampaign(data);
				toastSuccess('Creatives have been regenerated successfully');
			} catch (error) {
				toastError('Failed to regenerate creatives');
			} finally {
				setLoadingCreatives(false);
			}
		}
	};

	const modalHeader = (
		<HStack pr={10}>
			<Text fontSize="md">
				<Text as="span" fontWeight="bold">
					Editing:{' '}
				</Text>
				Option {initialValues?.variant}
			</Text>
			<Spacer />
			<Button
				size="sm"
				variant="blankOutline"
				onClick={() => setDesignerMode(true)}
			>
				Switch to designer mode
			</Button>
		</HStack>
	);

	const modalFooter = (
		<VStack align="stretch" spacing={4} width="100%">
			<Box
				w="100%"
				border="1px solid"
				borderColor="#E5E7EB"
				borderRadius="8px"
				bg="transparent"
				p={4}
			>
				<Checkbox
					isChecked={regenerateAll}
					onChange={(e) => setRegenerateAll(e.target.checked)}
					w="100%"
					sx={{
						whiteSpace: 'nowrap',
						'& .chakra-checkbox__label': {
							fontSize: '14px',
						},
						'& .chakra-checkbox__control[data-checked]': {
							backgroundColor: '#F7480B',
							borderColor: '#F7480B',
						},
					}}
				>
					Also regenerate content for all the channels
				</Checkbox>
			</Box>
			<HStack justify="flex-end" spacing={3}>
				<Button onClick={onClose}>Close</Button>
				<Button
					isLoading={isLoading}
					onClick={() => setIsSaving(true)}
					variant="orangeSolid"
					_hover={isLoading ? {} : { bg: 'orange.base' }}
				>
					Save changes
				</Button>
			</HStack>
		</VStack>
	);

	return (
		<CustomModal
			trapFocus={false}
			isOpen={isOpen}
			onClose={onClose}
			size="5xl"
			header={modalHeader}
			footer={modalFooter}
		>
			<EditDDForm
				initialValues={initialValues}
				isSaving={isSaving}
				setIsSaving={setIsSaving}
				setIsUploading={setIsUploading}
				onSave={handleSave}
				designerMode={designerMode}
				setDesignerMode={setDesignerMode}
				designDirections={designDirections}
				onRefetchDD={handleRefreshDesignDirections}
				closeEditModal={onClose}
			/>
		</CustomModal>
	);
};

export default EditDDModal;
