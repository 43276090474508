const config = {
	env: process.env.REACT_APP_ENV,
	app: {
		apiURL: process.env.REACT_APP_API_URL || '',
		genAiApiUrl: process.env.REACT_APP_GEN_AI_API_URL || '',
		appsApiUrl: process.env.REACT_APP_APPS_API_URL || '',
		assistantId: process.env.REACT_APP_ASSISTANT_ID || '',
	},
	apmlitude: {
		apiKey: process.env.REACT_APP_AMPLITUDE_API_KEY,
	},
	facebook: {
		clientId: process.env.REACT_APP_FACEBOOK_CLIENT_ID || '',
		configId: process.env.REACT_APP_FACEBOOK_CONFIG_ID || '',
		userConfigId: process.env.REACT_APP_FACEBOOK_USER_CONFIG_ID || '',
		scope: process.env.REACT_APP_FACEBOOK_SCOPE || '',
	},
	google: {
		clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
		// adWordsclientId: process.env.REACT_APP_GOOGLE_ADWORDS_CLIENT_ID || '',
	},
	twitter: {
		clientId: process.env.REACT_APP_TWITTER_CLIENT_ID || '',
	},
};

export default config;
