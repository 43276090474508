import { LegacyRef, ReactNode, forwardRef } from 'react';
import {
	Flex,
	FormControl,
	FormControlProps,
	FormErrorMessage,
	FormLabel,
	FormLabelProps,
	NumberInput,
	NumberInputField,
	NumberInputProps,
	Tooltip,
	Text,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

export interface CustomNumberInputProps {
	label?: string;
	id?: string;
	requirementsLabel?: string;
	placeholder?: string;
	error?: ReactNode;
	tooltip?: string;
	required?: boolean;

	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	inputProps?: NumberInputProps;
}

const CustomNumberInput = (
	{
		error,
		label,
		placeholder,
		requirementsLabel,
		tooltip,
		required,
		formControlProps,
		formLabelProps,
		inputProps,
	}: CustomNumberInputProps,
	ref: LegacyRef<HTMLInputElement>,
) => {
	const hasError = !!error;

	const renderLabelIndicator = () => {
		if (!required) return null;

		if (error) return <InfoIcon color="#e53e3e" />;

		return <Text color="#e53e3e">*</Text>;
	};

	const errorFocusProps = error
		? {
				boxShadow: '0 0 0 1px #E53E3E',
				borderColor: '#E53E3E',
		  }
		: {
				boxShadow: '0 0 0 1px #3182ce',
				borderColor: '#3182ce',
		  };
	const formattedValue =
		inputProps?.value !== undefined ? inputProps?.value : '';

	return (
		<FormControl isInvalid={hasError} {...formControlProps}>
			{label && (
				<FormLabel
					display="inline-block"
					fontSize="sm"
					fontWeight="medium"
					{...formLabelProps}
				>
					<Flex gap={1} alignItems="center">
						{label}
						{renderLabelIndicator()}
					</Flex>
					{requirementsLabel && (
						<Flex w="110%">
							<Text fontSize="12px" color="#959595">
								{requirementsLabel}
							</Text>
						</Flex>
					)}
				</FormLabel>
			)}
			{tooltip ? (
				<Tooltip label={tooltip} aria-label="Input tooltip">
					<NumberInput allowMouseWheel size="sm" {...inputProps} ref={ref}>
						<NumberInputField
							placeholder={placeholder}
							_focusVisible={errorFocusProps}
						/>
					</NumberInput>
				</Tooltip>
			) : (
				<NumberInput allowMouseWheel size="md" {...inputProps} value={formattedValue} ref={ref}>
					<NumberInputField
						placeholder={placeholder}
						fontSize="14px"
						_placeholder={{ color: '#A0AEC0', fontSize: '14px' }}
						_focusVisible={errorFocusProps}
					/>
				</NumberInput>
			)}
			{error && <FormErrorMessage>{error}</FormErrorMessage>}
		</FormControl>
	);
};

export default forwardRef(CustomNumberInput);
