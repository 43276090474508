import {
	FieldValues,
	FieldPath,
	useController,
	useFormContext,
	get,
} from 'react-hook-form';
import MultiSelectCreateableInput, {
	MultiSelectCreateableInputProps,
} from './MultiSelectCreateableInput';
import { IOption } from 'src/lib/schemas/misc';
import { RefObject } from 'react';

interface MultiSelectCreateableInputHookProps<T extends FieldValues>
	extends MultiSelectCreateableInputProps {
	name: FieldPath<T>;
	requirementsLabel?: string;
	disableCreateOption?: boolean;
	onOpenMenu?: (ref: RefObject<HTMLDivElement>) => void;
	direction?: 'row' | 'column';
	requirementsLabelStyle?: React.CSSProperties;
	isClearable?: boolean;
}

function MultiSelectCreateableInputHook<T extends FieldValues = FieldValues>({
	name,
	requirementsLabel,
	onValueChangeCallback,
	disableCreateOption,
	requirementsLabelStyle,
	onOpenMenu,
	direction,
	isClearable,
	...props
}: MultiSelectCreateableInputHookProps<T>) {
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext();
	const { field } = useController({ control, name });
	const { onChange, value } = field;
	const error = get(errors, name);

	const handleChange = (val: IOption['value'][] | IOption[]) => {
		onChange(val);
		onValueChangeCallback?.(val);
	};


	return (
		<MultiSelectCreateableInput
			name={name}
			isClearable
			value={value ?? []}
			requirementsLabel={requirementsLabel}
			requirementsLabelStyle={requirementsLabelStyle}
			onChange={handleChange}
			error={error?.message}
			disableCreateOption={disableCreateOption}
			{...props}
			ref={register(name).ref}
			onOpenMenu={onOpenMenu}
			direction={direction}
		/>
	);
}

export default MultiSelectCreateableInputHook;
