import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import {
	FormControl,
	FormControlProps,
	FormErrorMessage,
	FormLabel,
	FormLabelProps,
	Textarea,
	TextareaProps,
	Text,
	Flex,
	InputGroup,
	InputRightElement,
	VStack,
	Skeleton,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import CopyToClipboard from '../../CopyToClipboard';
import AssistantIcon from '../../AssistantIcon';

export interface TextareaInputProps {
	label?: string;
	id?: string;
	placeholder?: string;
	requirementsLabel?: string;
	error?: ReactNode;
	maxLength?: number;
	valueLength?: number;
	required?: boolean;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	textareaProps?: TextareaProps;
	copyToClipboard?: string;
	withAssistant?: boolean;
	content?: string;
	direction?: 'row' | 'column';
	handleOpenAssistantChat?: (ref?: HTMLDivElement) => void;
	requirementsLabelStyle?: React.CSSProperties;
	isLoading?: boolean;
	shouldShowSkeleton?: boolean;
}

const TextareaInput: FC<TextareaInputProps> = ({
	error,
	label,
	placeholder,
	requirementsLabel,
	maxLength,
	valueLength,
	required,
	formControlProps,
	formLabelProps,
	textareaProps,
	copyToClipboard,
	withAssistant,
	content,
	requirementsLabelStyle,
	direction = 'column',
	handleOpenAssistantChat,
	isLoading,
	shouldShowSkeleton = true,
}) => {
	const inputRef = useRef<HTMLDivElement | null>(null);
	const hasError = !!error;
	const [isFocused, setIsFocused] = useState(false);
	const [hasScroll, setHasScroll] = useState(false);

	const onOpenAssistant = () => {
		inputRef.current &&
			handleOpenAssistantChat &&
			handleOpenAssistantChat(inputRef.current);
	};

	const renderLabelIndicator = () => {
		if (!required) return null;

		if (error) return <InfoIcon color="#e53e3e" />;

		return <Text color="#e53e3e">*</Text>;
	};

	const errorFocusProps = error
		? {
				boxShadow: '0 0 0 1px #E53E3E',
				borderColor: '#E53E3E',
		  }
		: {
				boxShadow: '0 0 0 1px #3182ce',
				borderColor: '#3182ce',
		  };

	const handleAutoResize = (textareaElement: HTMLTextAreaElement) => {
		if (textareaElement.clientHeight > 150) {
			return;
		}
		textareaElement.style.height = 'auto';
		const newHeight = Math.min(textareaElement.scrollHeight, 150);
		textareaElement.style.height = `${newHeight}px`;
	};

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const textareaElement = e.target;
		handleAutoResize(textareaElement);

		if (textareaProps?.onChange) {
			textareaProps.onChange(e);
		}
	};

	useEffect(() => {
		const textareaElement = inputRef.current?.querySelector('textarea');
		const checkForScroll = () => {
			if (textareaElement) {
				const scrollPresent =
					textareaElement.scrollHeight > textareaElement.clientHeight;
				setHasScroll(scrollPresent);
			}
		};

		checkForScroll();

		textareaElement?.addEventListener('input', checkForScroll);

		return () => {
			textareaElement?.removeEventListener('input', checkForScroll);
		};
	}, [inputRef, content]);

	useEffect(() => {
		const textareaElement = inputRef.current?.querySelector(
			'textarea',
		) as HTMLTextAreaElement;
		if (textareaElement) {
			handleAutoResize(textareaElement);
		}
	}, [inputRef]);

	return (
		<FormControl isInvalid={hasError} {...formControlProps}>
			<Flex direction={direction} gap={direction === 'row' ? 20 : 0}>
				{label && (
					<Flex flex={1} justify="space-between">
						<FormLabel mb="1" {...formLabelProps}>
							<Flex gap={1} alignItems="center">
								{label}
								{renderLabelIndicator()}
							</Flex>
							{requirementsLabel && (
								<Flex>
									<Text
										fontSize="12px"
										color="#959595"
										style={requirementsLabelStyle}
									>
										{requirementsLabel}
									</Text>
								</Flex>
							)}
						</FormLabel>
						{copyToClipboard && (
							<CopyToClipboard value={copyToClipboard ?? ''} size="xs" />
						)}
					</Flex>
				)}
				{isLoading && shouldShowSkeleton ? (
					<Skeleton h="90px" w="full" borderRadius="5px" flex={1} />
				) : (
					<VStack flex={1} alignItems="flex-start">
						<InputGroup ref={inputRef}>
							{withAssistant && handleOpenAssistantChat && (
								<InputRightElement
									alignItems="end"
									h="full"
									width="10px"
									right={hasScroll ? 8 : 18}
								>
									<AssistantIcon onOpen={onOpenAssistant} />
								</InputRightElement>
							)}
							<Textarea
								resize="vertical"
								minH="80px"
								placeholder={placeholder}
								mt={1}
								pr={withAssistant ? 10 : 0}
								{...textareaProps}
								marginTop="9px"
								maxLength={maxLength}
								_focusVisible={errorFocusProps}
								onFocus={() => setIsFocused(true)}
								onBlur={() => setIsFocused(false)}
								onChange={handleChange}
								data-testid="text-area"
								overflow="auto"
							/>
						</InputGroup>
						<Flex
							alignItems="center"
							justifyContent={
								error && !maxLength
									? 'flex-start'
									: !error && maxLength
									? 'flex-end'
									: error && maxLength
									? 'space-between'
									: 'flex-end'
							}
						>
							{error && <FormErrorMessage mt={0}>{error}</FormErrorMessage>}

							{!!maxLength && (
								<Text color="#A0AEC0" textAlign="right" pr={2} pt={0.5}>
									{valueLength}/{maxLength}
								</Text>
							)}
						</Flex>
					</VStack>
				)}
			</Flex>
		</FormControl>
	);
};

export default TextareaInput;
